import React, { useState } from 'react'
import Image from '../../common/Image';
import api from '../../../modules/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login = (props) => {
    const { showLogin, handleSignupToggle, handleLoginToggle,handleForgotToggle } = props
    const handleSubmit = async (e) => {
        let btn = e.target;
        let frm = btn.form;
        let data = {}
        let error = [];
        frm.querySelectorAll("[name]").forEach((elm) => {
            if (!elm.validity.valid) {
                error.push(elm);
            } else {
                data[elm.name] = elm.value;
            }
        });
        if (error.length) {
            error[0].focus();
            return;
        }
        const API = new api();
        let response = await API.post("/api/v1/login", data);
        if (response.token && response.user) {
            localStorage.setItem("token", JSON.stringify(response.token));
            localStorage.setItem("user", JSON.stringify(response.user));
            toast.success('Login Successful!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            const timeout = setTimeout(() => {
                window.location.href = "/dashboard"
            }, 5000)
        } else {
            toast.error(`${response.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
    return (
        <>
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            <div className={showLogin ? "popup-login-form m-open" : "popup-login-form"} >
                <div className={"login-form"} >
                    <div className={"cross-bttn"} onClick={() => { handleLoginToggle() }} ></div >
                    <div className={"inner"} >
                        <div className={"image-wrapper"} >
                            <Image alt="Authentication" img="/images/home/forms/login-type.jpg" />
                        </div >
                        <div className={"content-wrapper"}>
                            <div className={"form-head"}>
                                <h3>Login with your account type</h3>
                            </div>
                            <form action="/api/v1/login/" onSubmit={handleSubmit} method="Post" autoComplete="off">

                                <div className={"form-field"} >
                                    {/* <span><i className={"fa fa-envelope"} aria-hidden="true"></i></span > */}
                                    <div className={"input-field"} >
                                        <frfs-email name="email" placeholder="Enter Your Email !" label="Email" editable required />
                                    </div >
                                </div >
                                <div className={"form-field"} >
                                    {/* <span><i className={"fa fa-lock"} aria-hidden="true"></i></span > */}
                                    <div className={"input-field"} >
                                        <frfs-password name="password" placeholder="Enter Your Password !" label="Password" complex={5} editable required />
                                    </div >
                                </div >
                                <div className={"form-forget"} >
                                    <span onClick={()=>{handleForgotToggle()}}><i className={"fa fa-key"} aria-hidden="true"></i> forget password ?</span >
                                </div >
                                <div className={"form-field"} >
                                    <div className={"input-field"} >
                                        <input type="button" onClick={handleSubmit} value="SUBMIT" />
                                    </div >
                                </div >
                            </form >

                            <div className={"join-account"} >
                                <span>or</span>
                                <p>Don't have an account? <a href={void (0)} id="login-join-panel" onClick={() => { handleLoginToggle(); handleSignupToggle() }} >Join Now</a></p>
                            </div >
                        </div >
                    </div >
                </div >
            </div>
        </>
    )
}
export default Login