
import React from "react"
import MUIDataTable from "mui-datatables";

const columns = [
    {
        name: "no",
        label: "No",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
        name: "title",
        label: "Survey Title",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "points",
        label: "Result",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "points",
        label: "Rewards",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "paymentstatus",
        label: "Payment Status",
        options: {
            filter: true,
            sort: true,
        }
    },
];

const data = [
    {
        "no": "1",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success success",
        "result": "complete",
        "points": "10pt",
        "paymentstatusclass": "success",
        "paymentstatus": "Done"
    }, {
        "no": "2",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success warning",
        "result": "overquota",
        "points": "10pt",
        "paymentstatusclass": "danger",
        "paymentstatus": "Done",
    }, {
        "no": "3",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success danger",
        "result": "terminate",
        "points": "10pt",
        "paymentstatusclass": "success",
        "paymentstatus": "Done",
    }, {
        "no": "4",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success warning",
        "result": "terminate",
        "points": "10pt",
        "paymentstatus": "pending",
        "paymentstatusclass": "danger",
    }, {
        "no": "5",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success success",
        "result": "complete",
        "points": "10pt",
        "paymentstatusclass": "success",
        "paymentstatus": "Done"
    }, {
        "no": "6",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success warning",
        "result": "overquota",
        "points": "10pt",
        "paymentstatusclass": "danger",
        "paymentstatus": "Done",
    }, {
        "no": "7",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success danger",
        "result": "terminate",
        "points": "10pt",
        "paymentstatusclass": "success",
        "paymentstatus": "Done",
    }, {
        "no": "8",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success warning",
        "result": "terminate",
        "points": "10pt",
        "paymentstatus": "pending",
        "paymentstatusclass": "danger",
    }, {
        "no": "9",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success success",
        "result": "complete",
        "points": "10pt",
        "paymentstatusclass": "success",
        "paymentstatus": "Done"
    }, {
        "no": "10",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success warning",
        "result": "overquota",
        "points": "10pt",
        "paymentstatusclass": "danger",
        "paymentstatus": "Done",
    }, {
        "no": "11",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success danger",
        "result": "terminate",
        "points": "10pt",
        "paymentstatusclass": "success",
        "paymentstatus": "Done",
    }, {
        "no": "12",
        "title": "Lorem, ipsum dolor.",
        "resultclass": "bg-success warning",
        "result": "terminate",
        "points": "10pt",
        "paymentstatus": "pending",
        "paymentstatusclass": "danger",
    },
]

const options = {
    filterType: 'checkbox',
};


const Table = (props) => {
    return (
        <>

            <MUIDataTable
                title={props.table}
                data={data}
                columns={props.columns || columns}
                options={options}
            />

        </>
    )
}

export default Table

