import React from 'react'
import FrequentSlider from '../slider/FrequentSlider'
const Data = {
    "first": [
        {
            "class": "fa-solid fa-user",
            "name": "External servey",
            "no": "6"
        }, {
            "class": "fa-solid fa-user",
            "name": "External servey",
            "no": "6"
        }, {
            "class": "fa-solid fa-user",
            "name": "External servey",
            "no": "6"
        },
    ], "second": [
        {
            "class": "fa-solid fa-user",
            "name": "External servey",
            "no": "6"
        }, {
            "class": "fa-solid fa-user",
            "name": "External servey",
            "no": "6"
        }, {
            "class": "fa-solid fa-user",
            "name": "External servey",
            "no": "6"
        },
    ], "third": [
        {
            "class": "fa-solid fa-user",
            "name": "External servey",
            "no": "6"
        }, {
            "class": "fa-solid fa-user",
            "name": "External servey",
            "no": "6"
        }, {
            "class": "fa-solid fa-user",
            "name": "External servey",
            "no": "6"
        },
    ],
}

const HorizontalSlider = () => {
    return (
        <>
            <div className="col-md-4">
                {Object.keys(Data).map((key, index) => {
                    return (
                        <div className="card" key={index+1}>
                            <div className="card-body">
                                <div className="card-slider-wrapper">
                                    <div className="card-slider">
                                        <div>
                                            <FrequentSlider vertical={true}>
                                                {Data[key].map((item,index) => (
                                                    <div className="item" key={index*10}>
                                                        <div className="icon">
                                                            <i className={item.class}></i>
                                                        </div>
                                                        <div className="content">
                                                            <h6>{item.name}</h6>
                                                            <p>{item.no}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </FrequentSlider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                }
            </div>

            {/* 
            <div className="card">
                <div className="card-body">
                    <div className="card-slider-wrapper">
                        <div className="card-slider">
                            <div  >
                                <FrequentSlider vertical={true}>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fa-solid fa-user"></i>
                                        </div>
                                        <div className="content">
                                            <h6>External servey</h6>
                                            <p>4</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fa-regular fa-rectangle-list"></i>
                                        </div>
                                        <div className="content">
                                            <h6>Suvery Participation</h6>
                                            <p>2</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fa-regular fa-rectangle-list"></i>
                                        </div>
                                        <div className="content">
                                            <h6>In House Surveys</h6>
                                            <p>5</p>
                                        </div>
                                    </div>
                                </FrequentSlider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="card-slider-wrapper">
                        <div className="card-slider">
                            <div >
                                <FrequentSlider vertical={true}>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fa-regular fa-rectangle-list"></i>
                                        </div>
                                        <div className="content">
                                            <h6>External servey</h6>
                                            <p>4</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fa-regular fa-rectangle-list"></i>
                                        </div>
                                        <div className="content">
                                            <h6>Suvery Participation</h6>
                                            <p>2</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fa-regular fa-rectangle-list"></i>
                                        </div>
                                        <div className="content">
                                            <h6>In House Surveys</h6>
                                            <p>5</p>
                                        </div>
                                    </div>
                                </FrequentSlider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="card-slider-wrapper">
                        <div className="card-slider">
                            <div >
                                <FrequentSlider vertical={true}>
                                    <div className="item">
                                        <div className="icon">
                                            <img src={`/images/Dashboard-Images/Dashboard-monthly.png`} alt="monthly survey" />
                                        </div>
                                        <div className="content">
                                            <h6>monthly survey</h6>
                                            <p>1</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <img src={`/images/Dashboard-Images/Dashboard-quarterly.png`} alt="quarterly servey" />
                                        </div>
                                        <div className="content">
                                            <h6>quarterly servey</h6>
                                            <p>1</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <img src={`/images/Dashboard-Images/Dashboard-yearly.png`} alt="yearly survey" />
                                        </div>
                                        <div className="content">
                                            <h6>yearly survey</h6>
                                            <p>1</p>
                                        </div>
                                    </div>
                                </FrequentSlider>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    )
}

export default HorizontalSlider