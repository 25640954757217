import React from "react";
import api from "../../../modules/api"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = (props) => {
  const {handleForgotToggle, showForgot} = props;
  const handleSubmit = async (e) => {
    let btn = e.target;
    let frm = btn.form;
    let data = {}
    let error = [];
    frm.querySelectorAll("[name]").forEach((elm) => {
        if (!elm.validity.valid) {
            error.push(elm);
        } else {
            data[elm.name] = elm.value;
        }
    });
    if (error.length) {
        error[0].focus();
        return;
    }
    const API = new api();
    let response = await API.post("/api/v1/user/forgot", data);
    if (response.status==="success") {
        toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        })
        const timeout = setTimeout(() => {
          handleForgotToggle()
      }, 5000)
    } else {
        toast.error(`${response.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
}
  return (
    <>
      <div id="forget-password-form" className={showForgot?"open-form":""}>
        <div className="inner">
          <button className="fa fa-times close" onClick={()=>{handleForgotToggle()}}></button>
          <div className="form-wrapper">
            <h1>Forgot Password</h1>
            <form action="/api/v1/user/forgot" onSubmit={handleSubmit} method="Post" autoComplete="off">
              <div className="field">
                <div classNameName="form-group">
                  <label htmlFor="forgotemail">
                    Registered E-Mail <span>*</span>
                  </label>
                  <frfs-email name="forgotemail" placeholder="Enter Your Email !" label="Email" editable required />
                </div>
              </div>
              <div className="field">
              <input type="button" className="verify"  onClick={handleSubmit} value="SUBMIT" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
