import React from 'react'
import { useNavigate } from "react-router-dom"

const SideNav = [
    {
        "nav": "/dashboard",
        "icon": "fa-solid fa-house-chimney",
        "h5": "Dashboard",
    },
    {
        "nav": "/my-account",
        "icon": "fa-solid fa-user",
        "h5": "My Account",
       
    }, {
        "nav": "/profiles",
        "icon": "fa-solid fa-user-doctor",
        "h5": "Profiles",
       
    },
    {
        "nav": "/surveyslist",
        "icon": "fa-solid fa-table-list",
        "h5": "Surveys",
       
    },
    {
        "nav": "/rewards",
        "icon": "fa-solid fa-award",
        "h5": "Rewards",
       
    }, {
        "nav": "/referals",
        "icon": "fas fa-user-friends",
        "h5": "Referals",
       
    }, {
        "nav": "/settings",
        "icon": "fa-solid fa-gear",
        "h5": "Settings",
       
    }, {
        "nav": "/support",
        "icon": "fa-solid fa-headset",
        "h5": "Support",
       
    },
]

const DashAside = () => {
    const navigate = useNavigate()
    return (
        <>
            <div id="dashboard">
                <aside id="dashboard-aside" >
                    <nav>
                        <ul>
                        {SideNav.map((item, i) => (
                            <li key={i}><a href={void(0)} onClick={() => { navigate(`${item.nav}`) }}>
                                <div className={"profile-card"}>
                                    <div className={"icon"}><i className={`${item.icon}`}></i></div>
                                    <div className={"content"}>
                                        <h5>{item.h5}</h5>
                                    </div>
                                </div>
                            </a>
                            </li>
                        ))}
                        </ul>
                    </nav>
                </aside>
            </div>
        </>
    )
}

export default DashAside