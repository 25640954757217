import React, { useState } from "react";
import Header from "../../components/visitor/Header";
import Footer from "../../components/visitor/Footer";
import Sign from "../../components/visitor/popup/Sign";

const Privacy = () => {
  const [showSign, setShowSign] = useState(false);

  return (
    <>
      <Header />
      <Sign showSign={showSign} setShowSign={setShowSign} />
      <section class="inner-banner">
        <div class="container">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <div class="content text-center">
                {/* <div class="icon">
                                    <img src="/images/star.png" alt="star" />
                                </div> */}
                <h3 className="text-capitalize font-bold">privacy policy</h3>
                <ul className="d-flex justify-content-center mt-4">
                  <li>
                    <a href="/">home</a>
                  </li>
                  <li>
                    <i class="fa-solid fa-angle-right"></i>
                  </li>
                  <li>
                    <p>privacy policy</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="go-to-top" id="topBtn">
        <i class="fa-solid fa-arrow-turn-up"></i>
      </div>
      <section class="section-padding bg-white content-page">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p>
                This Privacy Policy pertains to My Health Opinions ("My Health
                Opinions," "we," "us," and "our"), an entity associated with
                Frequent Research Fieldwork Solutions Private Limited. Our
                utmost priority at My Health Opinions is safeguarding the
                privacy of your personal information. Moreover, we are dedicated
                to thwarting any unauthorized access to this data. The Privacy
                Policy lays out the specifics regarding the collection of
                personal information from our members, participants, users,
                business representatives, and others. Additionally, it
                elucidates how we utilize this information, how it is securely
                stored, and provides you with choices concerning the use of your
                information.
              </p>
              <h3>WHO WE ARE</h3>
              <p>
                My Health Opinions is a leading provider of healthcare and
                pharmaceutical marketing research, distinguished by our
                exceptional panel of healthcare professionals. Our platform and
                services are designed to achieve larger sample sizes and
                enhanced accuracy, conducting surveys in adherence to market
                dynamics and industry codes of conduct. Our primary objective is
                to present intuitive visual story-based reporting based on the
                most reliable research data. My Health Opinions operates in
                India under the incorporation of Frequent Research Fieldwork
                Solutions Private Limited, established in 2017. Our proprietary
                panel of healthcare professionals is managed globally and
                includes primary care physicians, medical specialists, pharmacy
                professionals, physician assistants, nurses, and various other
                healthcare experts. We also survey individuals representing
                diverse health issues, generating valuable market research data
                categorized by geographical location, age bracket, and other
                significant factors. Our operations encompass the My Health
                Opinions website, located at <a href="#" className="privacy-link">https://myhealthopinions.com/</a> our
                related online survey and study platform powered by third-party
                software, and other associated research and business activities,
                collectively referred to as "Services." At My Health Opinions,
                we prioritize the privacy of your data and treat data
                safeguarding with the utmost seriousness. We encourage you to
                review this Privacy Policy carefully to understand the
                collection and usage of Personal Information (as defined below)
                from you and the choices you have regarding its use. If you do
                not agree with this Privacy Policy, we kindly ask you not to use
                our Services. "Personal Information" refers to data that
                identifies, relates to, describes, or could be reasonably
                linked, directly or indirectly, to a specific individual. It
                encompasses information that can be associated with or linked to
                an individual, as well as data that might pertain to a
                household, as defined by certain laws. The Personal Information
                we process includes data that you actively provide to us,
                information automatically collected during your use of our
                Services, and data obtained from third-party sources. The
                processing of your Personal Information may involve various
                actions, such as collecting, recording, organizing, structuring,
                storing, adapting, altering, retrieving, consulting, using,
                disclosing, transmitting, disseminating, aligning, combining,
                restricting, erasing, or destroying the data. Our collection of
                Personal Information is not indiscriminate; we limit it to what
                is reasonably necessary to provide a product or service, either
                directly or indirectly, and which is reasonably required for the
                purposes for which you have been informed. Additionally, we may
                collect information as authorized by applicable law. Personal
                Information that you actively submit to us includes the data you
                provide when interacting with our Services. This information is
                collected through various actions such as: Account Registration:
                When you register as a panellist, whether as a healthcare
                professional, patient, or individual participant, we ask you to
                provide your name, email, and password. Additionally, you can
                choose the preferred survey language, either English or a
                specific language relevant to your country. For healthcare
                professionals joining professional panels, we may collect key
                information about your profession, employment, experience, and
                certificate number if registered. For individuals joining
                consumer health ailment panels, we gather key information such
                as mailing address, residential city and postcode, gender,
                employment status, marital status, income, ethnicity, religion,
                gender, health issue diagnosis, and status of medical treatment.
                Some of these questions may be optional. Participation in
                Surveys, Interviews, and Focus Groups: When you participate in
                market research activities like online surveys, telephone
                interviews, focus groups, opinions, and general feedback, you
                provide information specific to the research being conducted.
              </p>
              <p>
                {" "}
                <strong>Subscriptions:</strong> You have the option to subscribe
                to our email or social communications related to topics of
                interest. You can choose to opt-in for specific content. Please
                note that we directly ask for this Personal Information, and you
                will be aware when we collect it. Some of this information may
                be necessary for you to access our Services or for us to contact
                you. However, in certain cases, providing specific Personal
                Information may be optional and will not affect your access to
                our Services. In the case of applicable market research
                activities, compensation may be provided in the form of a cheque
                sent to the address on file. We are committed to respecting your
                privacy and ensuring that the collected information is used
                solely for legitimate market research purposes.
              </p>
              <p>
                <strong>Email or Social Subscriptions:</strong> As a member of
                My Health Opinions, you have the option to subscribe to receive
                email or social communications related to topics of interest. By
                opting in, you can stay informed about the following: Latest
                Topical Data Findings and Company News: You will receive updates
                on the most recent data findings and news from our company.
                Contact Us / Request More Information: We offer an online form
                that allows users to get in touch with us or request additional
                information. When using this form, we collect your name, email
                address, company name, the type of request you have, and a
                description of the request. Additionally, we provide contact
                details, such as a phone number or the option to write to us
                directly. Social Media Engagement We invite users to join us on
                various social media platforms, including WhatsApp, YouTube,
                Twitter, LinkedIn, and Facebook. You can access these platforms
                directly from the My Health Opinions website through provided
                links. Personal Information Automatically Collected through
                Service Use: When you visit My Health Opinions Services, we
                automatically receive certain Personal Information. This
                includes details about the device you use, your browser, and
                operating system when accessing our Services. We also collect
                your IP address, the website that referred you to our site, the
                pages you request and visit, and the date and time of each
                request you make. Please note that our collection of this
                automatically collected Personal Information helps us improve
                the functionality and user experience of our Services. We are
                committed to using this data responsibly and for legitimate
                purposes only. Personal Information of Minors: The Services
                provided by My Health Opinions are not intended for users under
                the age of 14. We do not actively collect or process Personal
                Information from individuals under the age of 14. If we become
                aware that a user under the age of 14 has submitted Personal
                Information without verifiable parental consent, we will
                promptly remove their information from our files. Categories of
                Personal Information Collected in the Past 12 Months: In the
                preceding 12 months, we have collected the following categories
                of Personal Information from consumers: Identifiers: This
                includes information such as a real name, alias, postal address,
                email address, unique personal or online identifier, Internet
                Protocol (IP) address, and account name. Education, Employment
                Status, and Medical Information: We may collect information
                related to a user's education, employment status, and medical
                information. Protected Characteristics: This category includes
                information about a person's race, religion, gender identity,
                marital status, medical conditions, ethnicity, disability, and
                age. Internet or Electronic Network Activity Information: We
                collect information about a consumer's interaction with our
                website, including browsing history, search history, and
                information regarding their interaction with advertisements or
                other internet websites. Professional or Employment-Related
                Information: We may collect information related to a user's
                profession or employment. Cookies and Similar Tracking
                Technologies: To collect Personal Information, we and our
                third-party service providers use various technologies,
                including cookies. For detailed information on what cookies are,
                the types of cookies we use, the reasons for using them, and how
                you can manage their use, please refer to our Cookie Policy at
                the following link:
                https://www.myhealthopinions.com/cookie-policy-cookies/ We are
                committed to respecting your privacy and handling your Personal
                Information responsibly and in accordance with applicable laws
                and regulations.{" "}
              </p>
              <h3>CONSENT</h3>
              <p>
                {" "}
                Regarding the collection, use, or disclosure of Personal
                Information, we will obtain your consent, except where
                authorized or required by law to do so without consent. For
                instance, if the information is publicly available or if we need
                to seek legal advice or conduct investigations. Your consent can
                be express, implied, or given through an authorized
                representative, such as a lawyer, agent, or broker. It may be
                provided verbally, in writing, electronically, through inaction
                upon notice, or in any other appropriate manner. You have the
                right to withdraw your consent at any time, subject to legal,
                contractual, and other restrictions, provided you give us
                reasonable notice. After receiving such notice, we will inform
                you of the likely consequences of withdrawing consent, which may
                include our inability to provide certain services to you or to
                continue our relationship.
              </p>
              <h3>HOW WE USE YOUR PERSONAL INFORMATION</h3>
              <p>
                {" "}
                We use your Personal Information for various purposes: Operating
                our Services and fulfilling contractual obligations: We utilize
                your information to run our Services effectively and to meet our
                contractual obligations in our service contracts with customers.
                Improving content and services: Your Personal Information is
                analysed to understand how we can enhance our content, service
                offerings, and products for a better user experience.
                Administrative and business purposes: We process Personal
                Information for business-related activities such as payments,
                employee training, data analysis, security monitoring, auditing,
                research, and to comply with applicable laws and regulations.
                The legal basis for processing your Personal Information can
                vary. It may include the necessity to fulfill contractual
                obligations, our legitimate business interests as a provider of
                security services, compliance with regulatory requirements, or,
                in some cases, your explicit consent.
              </p>
              <h3>SPECIFIC PURPOSES FOR USING YOUR PERSONAL INFORMATION</h3>
              <p>
                {" "}
                My Health Opinions and its representatives use Personal
                Information for specific purposes, including:
              </p>
              <p>
                {" "}
                <strong>1.</strong> Authenticating your identity and confirming
                your credentials.
              </p>
              <p>
                {" "}
                <strong> 2.</strong> Determining your eligibility to participate
                in surveys.
              </p>
              <p>
                {" "}
                <strong>3.</strong> Contacting you to invite you to participate
                in surveys.
              </p>
              <p>
                <strong>4.</strong>
                Clarifying your responses to surveys.
              </p>
              <p>
                <strong> 5.</strong> Determining your eligibility for prizes
                related to survey participation.
              </p>
              <p>
                {" "}
                <strong>6.</strong>
                Providing and administering requested products and services.{" "}
              </p>
              <p>
                {" "}
                <strong>7.</strong>
                Protecting My Health Opinions, yourself, and others from fraud
                and error.
              </p>
              <p>
                {" "}
                <strong>8.</strong> Managing or transferring My Health Opinions'
                assets or liabilities, such as during acquisitions or mergers.
              </p>
              <p>
                <strong>9.</strong> Complying with legal requirements and acting
                pursuant to legal authorizations.{" "}
              </p>
              <p>
                {" "}
                We are committed to using your Personal Information responsibly
                and for the specified purposes mentioned above. Rest assured
                that your privacy and data protection are of utmost importance
                to us.
              </p>
              <h3>SHARING OF PERSONAL INFORMATION</h3>
              <p>
                We may share your Personal Information under the following
                circumstances.
              </p>
              <p>
                {" "}
                <strong>Our Customers:</strong> We disclose aggregate survey
                responses to our customers. However, we do not disclose
                personally identifiable information about you to our customers
                without obtaining your prior written consent, except when
                required or authorized by law.
              </p>
              <p>
                <strong>Service Providers:</strong> We may share the information
                we collect about you with third-party service providers who
                perform tasks on our behalf to support the Services. These
                service providers, also known as sub-processors, may include:
              </p>
              <p>
                {" "}
                <strong>1.</strong> Payment Providers: Providers who handle
                payment transactions for our Services.
              </p>
              <p>
                {" "}
                <strong>2.</strong> Hosting Services: Companies that provide
                hosting and storage facilities for our data.
              </p>
              <p>
                <strong>3.</strong> Sales and Marketing Providers: Entities that
                assist us in sales and marketing activities.
              </p>
              <p>
                <strong>4.</strong>
                Analytic Data Services: Service providers that help us with data
                analysis.
              </p>
              <p>
                {" "}
                <strong>5.</strong> Other Services: Providers offering system
                support and other related services. We ensure that our service
                providers handle your Personal Information in a manner
                consistent with our Privacy Policy and appropriate data
                protection laws.
              </p>
              <p>
                Governing Bodies: We may disclose your Personal Information to
                governing bodies, such as colleges of physicians and surgeons,
                as required or authorized by law or for regulatory compliance
                purposes. At My Health Opinions, protecting your privacy and
                personal data is fundamental. We take appropriate measures to
                safeguard your information when sharing it with third parties,
                ensuring it is used responsibly and in accordance with
                applicable laws and regulations. Regulatory Bodies, Government
                Departments, Agencies, and Law Enforcement. In certain
                circumstances, we may access and disclose your Personal
                Information to regulatory bodies, government departments,
                agencies, and law enforcement authorities. This action may be
                taken if we have a good-faith belief that such disclosure is
                required under applicable law or regulation. For instance, we
                may need to submit Personal Information to tax authorities to
                comply with tax laws. We may also disclose your Personal
                Information in response to lawful requests made by public
                authorities or law enforcement agencies, including those related
                to national security or law enforcement requirements. In cases
                where we are required to release your Personal Information to
                regulatory bodies or law enforcement, we will typically provide
                you with notice, unless we are legally prohibited from doing so
                by law or court order. Merger, Sale, or Other Asset Transfers:
                In the event of a merger, acquisition, financing due diligence,
                reorganization, bankruptcy, receivership, sale of company
                assets, or a transition of service to another provider, your
                Personal Information may be transferred as part of such a
                transaction, as permitted by law and/or contract. If such an
                event occurs, My Health Opinions will make efforts to ensure
                that the transferee handles your Personal Information in a
                manner consistent with the Privacy Policy in effect at the time
                of the original collection of your Personal Information. We
                value the confidentiality of your Personal Information and take
                appropriate steps to ensure that any transfer of data in the
                above-mentioned scenarios complies with applicable data
                protection laws and safeguards your privacy rights.
              </p>
              <p>
                {" "}
                Other Disclosures: We may also disclose your Personal
                Information in certain circumstances, including: Exercising or
                Defending Legal Rights. We may disclose your Personal
                Information to exercise or defend our legal rights or to take
                precautions against liability.
              </p>
              <p>
                {" "}
                Protecting Rights and Safety: Your Personal Information may be
                disclosed to protect the rights, property, or safety of the
                Services, individuals, or the general public. Maintaining
                Security and Integrity: We may disclose your Personal
                Information to maintain and protect the security and integrity
                of our services or infrastructure. Fraud Prevention and
                Investigation Your Personal Information may be disclosed to
                protect ourselves and our services from fraudulent, abusive, or
                unlawful uses, as well as to investigate and defend against
                third-party claims or allegations. Such disclosures may involve
                sharing information with courts of law, attorneys, law
                enforcement, or other relevant third parties to fulfill these
                purposes. Aggregate Information and Non-Identifying Data: We
                share aggregated information and non-identifying data with third
                parties for industry research, analysis, demographic profiling,
                and similar purposes. However, we do not disclose personally
                identifiable information to these third parties. Links to Other
                Websites: Our Services may contain links to other websites that
                are not controlled by us. These websites may also reference or
                link to our Services. We advise you to review the privacy
                policies applicable to these other websites. Service Providers
                and Limited Access to Personal Information: From time to time,
                our service providers may need to access databases containing
                Personal Information to provide services to us. However, their
                access and use of such information are strictly limited to the
                provision of services to our business. Categories of Personal
                Information Disclosed to Service Providers: The categories of
                Personal Information that may have been disclosed to service
                providers for this limited business purpose in the preceding 12
                months include:
              </p>
              <p>
                {" "}
                Identifiers: Such as a real name, alias, postal address, email
                address, unique personal or online identifier, Internet Protocol
                (IP) address, and account name. Education, Employment Status,
                and Medical Information: Information related to education,
                employment status, and medical details.
              </p>
              <p>
                Protected Characteristics: Information about race, religion,
                gender identity, marital status, medical conditions, ethnicity,
                disability, and age. Internet or Electronic Network Activity
                Information: Data related to browsing history, search history,
                and information regarding a consumer's interaction with websites
                or advertisements. Professional or Employment-Related
                Information: Details pertaining to profession or employment. No
                Sale of Personal Information We want to emphasize that we do not
                sell Personal Information to any third parties. At My Health
                Opinions, your privacy is a top priority. We adhere to strict
                policies and safeguards to ensure the confidentiality and
                security of your Personal Information, in accordance with
                applicable laws and regulations.
              </p>
              <h3>RETENTION OF PERSONAL INFORMATION </h3>
              <p>
                My Health Opinions retains Personal Information for a reasonable
                period to fulfil the processing purposes mentioned earlier.
                Afterward, the Personal Information is archived for time periods
                required by law or legal considerations. Once archival is no
                longer necessary, the Personal Information is deleted from our
                records. For Personal Information used in making decisions that
                affect you, we retain it for at least one year after using it to
                make the decision. Account Information Retention: Your account
                information, including Personal Information in your profile, is
                retained while your account is active. Upon termination or
                deactivation of your account, we delete your account as part of
                our standard deletion process. If you participated in market
                research, your contribution becomes part of the market data, and
                it is retained indefinitely, with the data anonymized and
                aggregated. Destruction of Personal Information: We will
                destroy, erase, or anonymize documents or records containing
                Personal Information when it is reasonable to assume that the
                original purpose for retaining the information is no longer
                being served, and retention is no longer necessary for legal or
                business purposes. However, please note that we may retain
                certain information within our internal systems for backup,
                archival, audit, or other legitimate purposes. Retention for
                Regulatory Obligations: Personal Information that we are
                required to retain to meet our regulatory obligations, including
                tax records and transaction history, will be retained
                accordingly. Regular Review and Compliance: We conduct regular
                reviews of our retention policy to ensure compliance with data
                protection laws and other regulatory requirements. As part of
                our compliance efforts, we also conduct audits of our databases
                and archived information to ensure that Personal Information is
                only stored and archived in accordance with our retention
                policies. At My Health Opinions, we take data retention
                seriously and strive to maintain a secure and responsible
                approach to managing Personal Information in line with legal and
                regulatory requirements.
              </p>
              <h3>PROTECTION OF PERSONAL INFORMATION:</h3>
              <p>
                {" "}
                At My Health Opinions, we prioritize the protection of Personal
                Information in our custody or control. We have implemented
                reasonable security arrangements to safeguard against accidental
                or unlawful destruction, loss, alteration, unauthorized
                disclosure, or access of your Personal Information. We take the
                necessary precautions to ensure that your data remains secure.
                Some specific safeguards we employ include:
              </p>
              <p>
                {" "}
                Physical Measures: We use locked filing cabinets and physical
                access controls to protect physical records containing Personal
                Information.
              </p>
              <p>
                Organizational Measures: Access to files and databases
                containing Personal Information is restricted only to authorized
                personnel as required, thereby minimizing the risk of
                unauthorized access.
              </p>
              <p>
                Electronic Measures: We utilize encryption, passwords, and
                firewalls to safeguard electronic data and protect against
                unauthorized access to our systems. Ensuring Comparable
                Protection by Suppliers and Agents. We take reasonable steps,
                through contractual or other means, to ensure that our suppliers
                and agents who assist in providing products and services to you
                also implement a comparable level of Personal Information
                protection.
              </p>
              Continuous Review and Improvement: We regularly review and
              consider appropriate new security technology and methods as we
              maintain and develop our software and systems, ensuring that we
              stay up-to-date with the latest security practices. No Guarantee
              of 100% Security: While we take diligent measures to ensure the
              security of your data, it is essential to note that no method of
              data transmission or storage can guarantee 100% security. Despite
              our best efforts, there is always a minimal inherent risk of
              unauthorized access or breach of security. Confidentiality and
              Security in Communication: Please be aware that confidentiality
              and security cannot be assured when information is transmitted
              through email or wireless communication. It is advisable to
              exercise caution and avoid sending sensitive or confidential
              information through such channels. At My Health Opinions, we are
              committed to protecting your Personal Information and adhering to
              best practices in data security. Our dedication to data protection
              extends to both physical and electronic records, and we
              continuously strive to improve our security measures to safeguard
              your information effectively.
              <h3>PRIVACY RIGHTS</h3>
              <p>
                {" "}
                As a user of My Health Opinions, you have certain privacy rights
                that you can exercise to manage your Personal Information
                Keeping Information Up-to-Date. If you have a My Health Opinions
                account, you are responsible for keeping your information up to
                date. You can edit your profile information at any time through
                your account settings.
              </p>
              <p>
                Email Subscriptions: You have the right to opt-out of email
                subscriptions at any time. You can do this by clicking the
                "Unsubscribe" link provided at the end of the email
                communication. Similarly, for marketing emails, you can directly
                unsubscribe from the email.
              </p>
              <p>
                {" "}
                Amending Inaccurate or Incomplete Information: If you find that
                your Personal Information is inaccurate or incomplete, you have
                the right to request corrections. Upon receiving your request,
                we will amend the information as required and, if applicable,
                inform third parties to whom the information has been disclosed.
              </p>
              <p>
                {" "}
                Annotations for Unresolved Challenges: In cases where a
                challenge regarding the accuracy of Personal Information is not
                resolved to your satisfaction, we will annotate the Personal
                Information under our control with a note that a correction was
                requested but not made.
              </p>
              <p>
                {" "}
                Privacy Rights under the CCPA: For residents of California, the
                California Consumer Privacy Act (CCPA) grants specific privacy
                rights. These rights include the right to: - Know what Personal
                Information is being collected about them. - Know whether their
                Personal Information is sold or disclosed and to whom. - Opt-out
                of the sale of Personal Information. - Access their Personal
                Information. - Receive equal service and price, even if they
                exercise their privacy rights. Note that we do not sell Personal
                Information. Respecting Privacy Rights: We respect the privacy
                rights of all individuals, regardless of their location, and
                encourage you to submit your requests if you have concerns about
                your Personal Information. You can contact us using the provided
                contact details at the end of this Privacy Policy. At My Health
                Opinions, we value your privacy and strive to provide
                transparency and control over your Personal Information. We are
                committed to addressing your privacy rights in a timely and
                effective manner.
              </p>
              <h3>EFFECTIVE DATE AND AMENDMENTS</h3>
              <p>
                {" "}
                This Privacy Policy is effective as of the date indicated at the
                top of this document under "Last updated." We may make
                amendments or updates to this Privacy Policy from time to time.
                Any changes will be reflected with a new "Last updated" date at
                the top of the document.
              </p>
              <h3>CONTACT INFORMATION</h3>
              <p>
                {" "}
                For any inquiries or concerns related to this Privacy Policy or
                the handling of your Personal Information, you can reach us
                through the following means: Email: support@frequentresearch.com
                Phone: 9810459700 My Health Opinions, C/O Frequent Research
                Fieldwork Solution Private Limited, B 96, Pushpanjali Enclave,
                Pitampura, Delhi - 110034 We encourage you to contact us if you
                have any questions, requests, or feedback regarding your privacy
                and data protection. Our team will be happy to assist you and
                address any concerns you may have.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={"panel-section sec-padding"}>
        <div className={"container"}>
          <div className={"row align-items-center"}>
            <div className={"col-md-8"}>
              <div className={"content-wrapper"}>
                <h3>Lets Get Started</h3>
                <p>
                  Be our community member and get rewarded with points and
                  credit paypal account after your loyal inputs
                </p>
              </div>
            </div>
            <div className={"col-md-4"}>
              <div className={"content-wrapper d-flex justify-content-center"}>
                <a
                  className={"bttn secondary-bttn"}
                  id="join-panel"
                  onClick={() => {
                    setShowSign(!showSign);
                  }}
                >
                  {" "}
                  join now
                  <span className="angle-icon">
                    <i class="fa-solid fa-angle-right"></i>
                    <i class="fa-solid fa-angle-right"></i>
                    <i class="fa-solid fa-angle-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Privacy;
