import React from "react";

export default function Image(attributes) {
    let path = attributes.img.split("/");
    let file = path.pop();
    let temp = file.split(".");
    let type = temp[1];
    let img = path.join("/") + "/" + temp[0].toLowerCase().replace(/[^\w\s]/gi, "-").replace(/ /g, "-");
    let alt = attributes.alt || "";
    return (<picture>
        {/* <source srcSet={`${img}.webp`} type="image/webp" /> */}
        <source srcSet={`${img}${(type == "jpg") ? ".jpg" : ".png"}`} type={`image/${(type == "jpg") ? "jpeg" : "png"}`} />
        <img src={`${img}.${type}`} alt={`${alt}`} loading="lazy" />
    </picture>)
}