import React, { useState } from "react";
import api from "../../modules/api";
import Header from "../../components/visitor/Header";
import Footer from "../../components/visitor/Footer";
import Sign from "../../components/visitor/popup/Sign";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <img className={"chevron"} src={"/images/angle.svg"} alt="Chevron Down" />
      </>
    }
    className={"item"}
    buttonProps={{
      className: ({ isEnter }) =>
        `${"itemBtn"} ${isEnter && "itemBtnExpanded"}`,
    }}
    contentProps={{ className: "itemContent" }}
    panelProps={{ className: "itemPanel" }}
  />
);

const FAQ = () => {
  const [showSign, setShowSign] = useState(false);
  const [faq, setFAQ] = React.useState([]);
  React.useEffect(() => {
    let API = new api();
    API.get("/api/v1/faq").then((faqs) => setFAQ(faqs.data));
  }, []);
  return (
    <>
      <Header />
      <Sign showSign={showSign} setShowSign={setShowSign} />
      <section className="inner-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center">
              <div className="content text-center">

                <h3 className="text-capitalize font-bold">
                  Frequent asked questions
                </h3>
                <ul className="d-flex justify-content-center mt-4">
                  <li>
                    <a href="/">home</a>
                  </li>
                  <li>
                    <i className="fa-solid fa-angle-right"></i>
                  </li>
                  <li>
                    <p>faq</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="accordion-bg section-padding bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content text-center">
                <h3 className="font-bold mb-5">
                  Do You Have <span>Any Question?</span>
                </h3>
              </div>
            </div>
            <div className="col-12">
              <div className={"styles.accordion"}>
                <Accordion transition transitionTimeout={250}>
                  <div className={"row"}>
                    <div className={"col-12"}>
                      <AccordionItem
                        header="How do I become a member"
                        initialEntered
                      >
                        <p>
                          My Health Opinions is dedicated healthcare panel for Doctors, Caregiver and Patient category.

                          To register on My Health Opinions, You need to choose the respective category before registration. You will be asked to provide some basic questions while creating your account, as well as the option to provide some more in-depth information about yourself in order to participate in our surveys. After completing the signup form, My Health Opinions panel will sent a confirmation email into your registered email ID in order to confirm the profile validity and finalize your registration process. Alternatively, You may click the link in your email to confirm your registration over panel and to receive the survey access.

                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12 mt-4"}>
                      <AccordionItem
                        header="Any hidden charges while registration?"
                        initialEntered
                      >
                        <p>
                          Registration to My Health Opinions is completely free - you will not be asked for any payment. On the contrary, it is you who earns points that can be exchanged for PayPal, UPI, Wire Transfer, Gift vouchers etc., by taking participation in our sponsored surveys.
                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="Why should I become a member of My Health Opinions?"
                      >
                        <p>
                          Becoming a member, let you take part in surveys on different topics. You can then start to accumulate the rewards after achieving the minimum threshold points. You can redeem for an incentive of your choice from “Redeem Now” button from Dashboard.
                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="How do I change my email address and password?"
                      >
                        <p>
                          Due to security, My Health Opinions don’t allow to update the primary email ID for the profile. You may contact support team at support@frequentresearch.com for any type of email update request.

                          You can change your password after login to the My Health Opinions Dashboard, Go to top right My Profile section and select “Change Password” to update your existing password.</p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="How can I edit my personal information?"
                      >
                        <p>
                          My Health Opinions Panel allow all users to change personal information with limited access only. Some of the basic personal information could not updated such as Gender, Age or Date of birth those provided at the time of registration.
                          You can update any information from left section profile categories. Update the information and save.
                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="How can I cancel my account?"
                      >
                        <p>
                          My Health Opinions respect all user data privacy so user can request for Account Deletion.

                          For Cancel/Delete your account, User need to login and go to top right my profile section then choose Delete Account.

                          After request for deletion, My Health Opinions will require a user confirmation through registered email ID. User can restore the account within 30 days otherwise all account data will be removed from the My Health Opinions Database and user need to create a new account for account activation.

                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="How do I participate in Surveys?"
                      >
                        <p>
                          My Health Opinions always share the survey according to the user preference and input provided by the user. User will get the notification for new surveys by email, dashboard popup information and alert for new surveys.

                          All surveys will appear at the dashboard with basic information and proceed by click the “Start Now” button on respective surveys.

                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="How many points will I earn for my participation?"
                      >
                        <p>
                          All reward points depends upon the survey information and these details will always appears to My Health Opinions dashboard. User can check some basic survey information along with reward point and proceed for the surveys
                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="How many surveys will I be invited to?"
                      >
                        <p>
                          My Health Opinions always share the survey according to the user preference and input provided by the user. User will get the notification for new surveys by email, dashboard popup information and alert for new surveys. You can login to your account and you will get the available survey information at the dashboard under “External Survey”.

                          My Health Opinions always encourage you to keep your profile up to date and increase the chance of getting more surveys.

                          The number of surveys you receive will vary depending on what personal settings you have and how many surveys are currently available. You can login to your account and under "Profile" tab, you can select "Ideal survey frequency" as per your choice. We encourage you to keep your profile up to date and increase the chance of getting more surveys.

                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="Technical difficulties with a survey, and why does this happen?"
                      >
                        <p>
                          One of the major reason behind technical issues is that survey respondents are using a variety of operating systems, browsers, Internet connection types, and hardware, and not all are necessarily compatible with the technology of all surveys that may be available.

                          If you encounter a technical difficulty during any survey. Usually, if you wait for a while, and try again later, the problem will be resolved and the survey resumed where the user left otherwise if you are still seeing an error after waiting a while, please report it to support team at support@frequentresearch.com along with error information screenshot, if possible. We apologize for any inconvenience caused by technical errors. We will do our best to get them resolved.

                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="Why does My Health Opinions ask the same questions in almost every survey?"
                      >
                        <p>
                          My Health Opinions profiling process is continuous. The objective is to have broad and current attributes on each panel member. Our clients makes decisions based on responses from our user in their surveys. Implementing strict quality controls helps us make sure you are part of the target the client is looking for. The questions at the beginning of each survey may seem repetitive but are necessary to ensure the integrity of panellists.
                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="When I click on the survey link, it says 'Survey is closed'. What does that mean?"
                      >
                        <p>
                          From usual experience, My Health Opinions understand that not everyone has time or opportunity to participate in all the surveys we send out. For one of the instance, user might be on vacation and cannot participate in a specific survey on time.

                          My Health Opinions launch survey to large number of people and it gets closed when desired number of participants have been reached. Thereafter, no more user can participate in the survey. If you get this message, it means My Health Opinions already have number of participants the survey is looking for.

                          User need to keep update and login usually to check the survey offers.

                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="How will I receive my rewards?"
                      >
                        <p>
                          Once the user achieve the minimum threshold and can able to request to redeem earn points.

                          The rewards could be in any form i.e. PayPal, Gift Vouchers, Bank Transfer etc., All payment form could be different as per the country law.

                          User ensure all of your information is correct to avoid any delays.

                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="How long will it take to receive my reward once redeemed?"
                      >
                        <p>
                          Once the user request for point redemption, My Health Opinions Support team check the validity of the profile and survey completion then proceed to share the information to accounts department for credit procedure.

                          All payment procedure could take up to 2-3 weeks before the transfer is actually executed. This could be due to several other transfers being queued for execution.

                          User ensure all of your information is correct to avoid any delays.

                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="What is done with the information I filled out when signing up?"
                      >
                        <p>
                          My Health Opinions respect the user data privacy policy. Your profile data is stored on our secured server that allows us to call upon those members who match specific criteria to participate in the surveys. This process is completely automated and members are contacted by My Health Opinions via email to participate in our surveys. All responses are held in aggregate, which means that individual responses are not linked to personally identifiable information. The information you provide will never be sold or used to advertise. You are not responsible for buying any product or service at any time, nor will any company solicit you as a result of joining My Health Opinions panel.
                        </p>
                      </AccordionItem>
                    </div>

                    <div className={"col-12  mt-4"}>
                      <AccordionItem
                        header="Why do you ask questions about age, income and other personal information?"
                      >
                        <p>
                          This information is used to match members with specific survey requirements. In some cases, our clients require feedback from people in a certain income bracket or those who have children.

                          Please be assured that this information is securely kept and strictly confidential. The more information you provide, the greater are your chances of receiving invitations to surveys you may qualify for, and the more opportunities you may receive to earn rewards. Go through our privacy policy for more details.

                        </p>
                      </AccordionItem>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={"panel-section sec-padding"}>
        <div className={"container"}>
          <div className={"row align-items-center"}>
            <div className={"col-md-8"}>
              <div className={"content-wrapper"}>
                <h3>Lets Get Started</h3>
                <p>
                  Be our community member and get rewarded with points and
                  credit paypal account after your loyal inputs
                </p>
              </div>
            </div>
            <div className={"col-md-4"}>
              <div className={"content-wrapper d-flex justify-content-center"}>
                <a
                  className={"bttn secondary-bttn"}
                  id="join-panel"
                  onClick={() => {
                    setShowSign(!showSign);
                  }}
                >
                  {" "}
                  join now
                  <span className="angle-icon">
                    <i className="fa-solid fa-angle-right"></i>
                    <i className="fa-solid fa-angle-right"></i>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FAQ;
