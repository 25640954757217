import React from 'react'
import Header from "../../components/visitor/Header";
import Footer from "../../components/visitor/Footer";
import api from "../../modules/api"
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ResetPassword = () => {
    const { key } = useParams()
    const handleSubmit = async (e) => {
        let btn = e.target;
        let frm = btn.form;
        let data = {}
        let error = [];
        frm.querySelectorAll("[name]").forEach((elm) => {
            if (!elm.validity.valid) {
                error.push(elm);
            } else {
                data[elm.name] = elm.value;
            }
        });
        data.key = key
        if (error.length) {
            error[0].focus();
            return;
        }
        const API = new api();
        let response = await API.post("/api/v1/user/resetpass", data);
        if (response.status === "success") {
            toast.success(`${response.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            const timeout = setTimeout(() => {
                window.location.href = "/"
            }, 5000)
        } else {
            toast.error(`${response.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
    return (
        <>
            <Header />
            <section class="section-padding bg-white content-page quality-standard">
                <div class="container">
                    <div class="row justify-content-center">
                        <div className="col-12 col-md-6 g-5">
                            <div className="set-password-main">
                                <div className="password-head d-flex align-items-center">
                                    <div className="icon">
                                        <i class="fa-solid fa-key"></i>
                                    </div>
                                    <h2>Set Password</h2>
                                </div>
                                <div className="password-body">
                                    <div className="icon">
                                        <i class="fa-solid fa-user-lock"></i>
                                    </div>
                                    <form action="/api/v1/user/resetpass" onSubmit={handleSubmit} method="Post" autoComplete="off">
                                        <div className="form-group">
                                            {/* <label htmlFor="">new password</label> */}
                                            {/* <input type="password" /> */}
                                            {/* <i class="fa-solid fa-lock"></i> */}
                                            <frfs-password name="password" placeholder="Enter Your Password !" label="New Password" complex={5} editable required />
                                        </div>

                                        <div className="form-group">
                                            {/* <label htmlFor="">confirm new password</label> */}
                                            {/* <input type="password" /> */}
                                            {/* <i class="fa-solid fa-lock"></i> */}
                                            <frfs-password name="cnf_password" placeholder="Enter Your Confirm Password !" label="Confirm Password" parent="password" message="Password in not matching" complex={5} editable required />
                                        </div>
                                        {/* <button type='submit'>reset password</button> */}
                                        <input type="button" onClick={handleSubmit} value="SUBMIT" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ResetPassword