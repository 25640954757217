import React from 'react'
import { useEffect, useState, createElement } from "react";
import api from "../../modules/api";
import DashHeader from '../../components/common/DashHeader'
import DashAside from '../../components/common/DashAside'


const FormBuilder = {
    step: {},
    endpoint: "",
    pages: 0,
    uInfo: {},
    cn: "",
    currentPage: 0,
    init: (info, step, uInfo, cn) => {
        FormBuilder.cn = cn;
        if (!info.pages) return <></>
        FormBuilder.step = step;
        FormBuilder.uInfo = uInfo;
        if (("pages" in info) && (info.pages instanceof Array))
            FormBuilder.pages = info.pages.length;
        return (
            <>
                <ul id={"progressbar"}>{FormBuilder.showSteps(info.pages)}</ul>
                <div className={"form-head"}>
                    <h4>{info.title || ""}</h4>
                    <p>{info.subtitle || ""}</p>
                </div>
                <div className={"form-wrapper"}>{FormBuilder.page(info.pages)}</div>
            </>
        );
    },
    showSteps: (pages) => {
        return pages.map((page, index) => {
            return (
                <li key={(index + 1)}
                    className={`${FormBuilder.currentPage === index ? "active" : ""
                        } account`}
                >
                    <strong>Step {index + 1}</strong>
                </li>
            );
        });
    },
    page: (pages) => {
        const curPage = pages[FormBuilder.currentPage];
        return (
            <fieldset>
                <div className={"row"}>{FormBuilder.showQuestions(curPage)}</div>
                <button
                    type="button"
                    className={"next action-button btn btn-primary"}
                    onClick={FormBuilder.frmNext}
                >
                    {(FormBuilder.pages === (FormBuilder.currentPage + 1)) ? "Submit" : "Next"}
                </button>
            </fieldset>
        );
    },
    showQuestions: (questions) => {
        return questions.map((que, index) => FormBuilder.showQuestion(que, index));
    },
    showQuestion: (que, i) => {
        que["key"] = "key" + i;
        switch (que.type) {
            case "text":
                return createElement("frfs-text", que);

            case "gender":
                return createElement("frfs-gender", que);

            // case "tel":
            //     return createElement("frfs-tel", que);

            case "yob":
                return (
                    <>
                            <div className={"col-md-6"}>
                                <div className={"row"}>
                                    {/* <div className={"col-md-6"}>
                                    <frfs-select
                                        sort="-1"
                                        label="Year of birth"
                                        api="/api/v1/options/yob"
                                        method="get"
                                        name="yob"
                                        required="true"
                                    ></frfs-select>
                                </div> */}
                                    <div className={"col-md-12"}>
                                        <frfs-text
                                            name="age"
                                            parent="yob"
                                            api="/api/v1/options/age"
                                            method="post"
                                            label="Age"
                                        ></frfs-text>
                                    </div>
                                </div>
                            </div>
                  
                    </>
                );

            case "select":
                return createElement("frfs-select", que);

            case "editor":
                return createElement("frfs-editor", que);


            case "email":
                return createElement("frfs-email", que);


            case "password":
                return createElement("frfs-password", que);

            case "payment":
                return (
                        <div className={"col-md-12"}>
                            <div className={"que"}>
                                <h4>
                                    Which payment mode you want us to credit your claimed rewards?
                                </h4>
                            </div>
                            <div className={"ans"}>
                                <div className={"radio-area"}>
                                    <div className={"radio-fields"}>
                                        <input
                                            type="radio"
                                            value="Paypal"
                                            name="payment"
                                            id="Paypal"
                                        />
                                        <label className={"radio-label"} htmlFor="Paypal">
                                            <img src={`/images/rewards/paypal.png`} alt="Paypal" />
                                            <span>Paypal</span>
                                        </label>
                                    </div>
                                    <div className={"radio-fields"}>
                                        <input type="radio" value="UPI" name="payment" id="UPI" />
                                        <label className={"radio-label"} htmlFor="UPI">
                                            <img src={`/images/rewards/upi.png`} alt="Upi" />
                                            <span>UPI</span>
                                        </label>
                                    </div>
                                    <div className={"radio-fields"}>
                                        <input type="radio" value="wire" name="payment" id="wire" />
                                        <label className={"radio-label"} htmlFor="wire">
                                            <img src={`/images/rewards/wire-transfer.png`} alt="Wire Transfer" />
                                            <span>Wire Transfer</span>
                                        </label>
                                    </div>
                                    <div className={"radio-fields"}>
                                        <input
                                            type="radio"
                                            value="report"
                                            name="payment"
                                            id="report"
                                        />
                                        <label className={"radio-label"} htmlFor="report">
                                            <img src={`/images/rewards/medical-report.png`} alt="Medical Report" />
                                            <span>Medical Report</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
         
                );
            case "terms":
                return (
 
                        <div className={"col-md-12 mt-20"}>
                            <label className="terms" htmlFor="terms">
                                <input type="checkbox" name="terms" id="terms" required />I agree for My
                                Health Opinions Panel to use my information for the purposes of
                                determining my eligibility and for contacting me in relation to
                                participation in market research surveys. Also, I am agreeing to
                                <a href="/term"> terms & condition </a>
                                and
                                <a href="/policy/privacy"> Privacy Policy </a>
                                for further details.
                            </label>
                        </div>
                   
                );
            default:

        }
    },
    frmNext: async (e) => {
        let btn = e.target;
        let frm = btn.form;
        let data = FormBuilder.uInfo.userInfo;
        let error = [];
        frm.querySelectorAll("[name]").forEach((elm) => {
            if (!elm.validity.valid) {
                error.push(elm);
            } else {
                data[elm.name] = elm.value;
            }
        });
        if (error.length) {
            error[0].focus();
            return;
        }
        if (FormBuilder.currentPage + 1 === FormBuilder.pages) {
            const API = new api();
            let response = await API.put(FormBuilder.endpoint, data);
            switch (response.status) {
                case "success":
                    let thankyouMessage = document.createElement("frfs-message");
                    thankyouMessage.setAttribute("title", response?.data?.title || "");
                    thankyouMessage.setAttribute("lifetime", response?.data?.lifetime || 30000);
                    thankyouMessage.setAttribute("redirect", response?.data?.redirect || "/");
                    thankyouMessage.setAttribute("type", response?.data?.type || "success");
                    thankyouMessage.setAttribute("message", response?.data?.message || "Form successfully submit ho gaya");
                    document.body.appendChild(thankyouMessage);
                    break;
                case "error":
                    let errorMessage = document.createElement("frfs-message");
                    errorMessage.setAttribute("title", response?.data?.title || "");
                    errorMessage.setAttribute("lifetime", response?.data?.lifetime || 300000);
                    errorMessage.setAttribute("redirect", response?.data?.redirect || "/");
                    errorMessage.setAttribute("type", response?.data?.type || "error");
                    errorMessage.setAttribute("message", response?.data?.message || "Kuch to kand hua hai");
                    document.body.appendChild(errorMessage);
                    break;
                default:
                    return false;
            }
            return;
        }
        FormBuilder.uInfo.setUserInfo(data);
        FormBuilder.currentPage++;
        FormBuilder.step.setStep(FormBuilder.step.step + 1);
    },
};

const Profiles = () => {
    const [questions, setQuestions] = useState('patient')
    const [regFormQBank, setregFormQBank] = useState({});
    const [cn, setCN] = useState("in");
    const [step, setStep] = useState(1);
    const [userInfo, setUserInfo] = useState({});
    useEffect(() => {
        const API = new api();
        API.get("/api/v1/options/country/my").then(info => setCN(info.data[0].code));
        if (questions) {
            FormBuilder.endpoint = `/api/v1/register/patient`;
            API.get(`/formData/${questions}`).then((e) => {
                if (!!e) {
                    setregFormQBank(e);
                }
            });
        }
    }, [questions]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("input data", e)
    };
    return (
        <>
            <div id="dashboard">
                <div className={"wrapper"}>
                    <DashHeader />
                    <DashAside />
                    <main>
                        <div className="row">
                            <div className="col-md-12">
                                {/* kindly add className="btn btn-primary" in every button  */}
                                <button className="btn btn-primary" onClick={() => { setQuestions('patient') }}>General</button>
                                <button className="btn btn-primary adv" onClick={() => { setQuestions('doctor') }}>Advanced</button>

                                <div className={"inner-page-body-content"}>
                                    <section id={""}>
                                        <form
                                            onSubmit={handleSubmit}
                                            method="Post"
                                            className={""}
                                        >
                                            {FormBuilder.init(
                                                regFormQBank,
                                                { step, setStep },
                                                { userInfo, setUserInfo },
                                                cn.toLowerCase()
                                            )}
                                        </form>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default Profiles