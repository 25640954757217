import './App.css';
import Popup from './PopupContext';
import { AllRoutes } from './Routes/AllRoutes';

function App() {
  return (<div className="App">
    <Popup>
      <AllRoutes />
    </Popup>
  </div>)
}


export default App;

