import React from 'react'
const Solutionsectiondata = [
    {
        "h4": "Verified Recruitment",
        "p": "Every Recruitment takes place in double optin process to verify every panelist"
    }, {
        "h4": "Advance Targeting",
        "p": "Panelist Targeting with more profiling questions i.e. Decision Making, Job Position, Diseases spacialization etc"
    }, {
        "h4": "Quality Data Sample",
        "p": "Occasinally verified panel to remove bad response and provide only quality survey responses"
    },
]

const SolutionList = () => {
    return (
        <>
            {Solutionsectiondata.map(item => (
                <div key={item.p} className={"col-md-4 col-lg-4"} >
                    <div className={"box"} data-aos="fade-up" data-aos-duration="1000" >
                        <div className={"box-content"} >
                            <h4>{item.h4}</h4>
                            <p>{item.p}</p>
                        </div >
                    </div >
                </div >
            ))}
        </>
    )
}

export default function Solutions(){
    return (<section className={"solutions-section sec-padding"}>
    <div className={"container"}>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <div className={"sec-head text-center"}>
                    <h3 className={"font-bold text-uppercase"}> Solutions</h3>
                </div>
            </div>
        </div>
        <div className={"content-wrapper"}>
            <div className={"row"}>
                <SolutionList />               
            </div>
        </div>
    </div>
</section>)
}