import React from 'react'
import CountUp from "react-countup";
const counterdata = [
    {
        "duration":30,
        "end":3045127,
        "title": "registered panellist"
    },
    {
        "duration":20,
        "end":14016,
        "title": "incentives in april"
    },
    {
        "duration":10,
        "end":50,
        "title": "monthly surveys"
    },
    {
        "duration":30,
        "end":27477,
        "title": "completed surveys"
    },
]

const CounterList = () => {
    return (
        <>
            {counterdata.map(item => (
                <li key={item.title} className={"counter"}>
                    <CountUp duration={item.duration} className={"data counter-value"} end={item.end} />
                    <div className={"title"}>{item.title}</div>
                </li>
            ))}
        </>
    )
}

export default function Counter(){
    return (<section className={"counter-bg section-padding"}>
    <div className={"container"}>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <div className={"content"}>
                    <ul>
                        <CounterList />
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>)
}
