import React from 'react'
import DashHeader from '../../components/common/DashHeader'
import DashAside from '../../components/common/DashAside'
import Tour from '../../components/Dashboard/Tour';
import Table from '../../components/common/Table';
import HorizontalSlider from '../../components/Dashboard/HorizontalSlider';
import SurveySlider from '../../components/Dashboard/SurveySlider';

const Dashboard = () => {
    return (
        <>
           <div id="dashboard">
           <Tour />
            <div className={"wrapper"}>
                <DashHeader />
                <DashAside />
                <main>
                    <div className="row">
                        <HorizontalSlider />

                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="profile-reward-section">
                                        <div className="left">
                                            <div className="profile-area">
                                                <div className="image-wrapper">
                                                    <img src={`/images/Dashboard-Images/Dashboard-avatar.jpg`} alt="profile" />
                                                </div>
                                                <div className="content">
                                                    <h4>abhishek panwar</h4>
                                                    <p>Get 1300 more points to redeem</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="bar">
                                                <span style={{ height: "80%" }}></span>
                                            </div>
                                            <div className="content-wrapper">
                                                <h5>points Created: <b>200</b><span>100 points = USD 1</span></h5>
                                                <a href={void (0)} className="btn btn-primary">Redeem now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="survey-slider">
                                        <SurveySlider />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="title">participation surveys</div>
                                </div>
                                <Table />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
           </div>
        </>
    )
}

export default Dashboard