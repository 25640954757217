import api from "./modules/api";

export const callAllEndpoints = function ({endpoints}) {
    return new Promise((resolve, reject) => {
        let API = new api()
        Object.values(endpoints)
            .forEach(key => {
                if (localStorage.getItem(key)) {
                    return resolve(JSON.parse(localStorage.getItem(key)));
                }
                API
                    .get(key)
                    .then(r => {
                        localStorage.setItem(key,JSON.stringify(r.data))
                        return resolve(r.data)
                    })
                    .catch(err => {
                        return reject(err)
                    })

            })
    })

}
