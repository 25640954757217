import React from 'react'
import { useNavigate, Link } from "react-router-dom"
const FooterData = [
  {
    "href": "#about",
    "name": "About Us",
    "new": ""
  }, {
    "href": "/policy/privacy",
    "name": "Privacy Policy",
    "new": "_blank"
  }, {
    "href": "/policy/cookies",
    "name": "Cookies Policy ",
    "new": "_blank"
  }, {
    "href": "/policy/gdpr",
    "name": "GDPR Compliance ",
    "new": "_blank"
  }, {
    "href": "/pending",
    "name": "Terms of Use ",
    "new": "_blank"
  }, {
    "href": "https://panels.frequentresearch.com/",
    "name": "Healthcare Panel Book ",
    "new": "_blank"
  }, {
    "href": "/profiling",
    "name": "Profiling and Quality Scoring ",
    "new": "_blank"
  }, {
    "href": "/faq",
    "name": "FAQ ",
    "new": "_blank"
  },
]

const MiddleData = [
  {
    "href": "https://www.facebook.com/frequentrfs",
    "logo": "fa fa-facebook"
  }, {
    "href": "https://twitter.com/frequentrfs",
    "logo": "fa fa-twitter"
  }, {
    "href": "https://www.linkedin.com/company/13338885",
    "logo": "fa fa-linkedin"
  }, {
    "href": "https://www.youtube.com/channel/UCLxmn2kq4aACAHaL2eT6QIQ",
    "logo": "fa fa-youtube"
  }
]

const FooterContact = [
  {
    "i": "fa fa-map-marker",
    "p": `Third Floor, B-96, Pushpanjali Enclave, Pitampura, Delhi 110034 India`
  }, {
    "i": "fa fa-phone",
    "href": "tel:+919810459700",
    "name": "+919810459700"
  }, {
    "i": "fa fa-envelope",
    "href": "mailto:support@frequentresearch.com",
    "name": "support@frequentresearch.com"
  }
]

const Footer = () => {
  const navigate = useNavigate()
  return (
    <footer>
      <div className={"container"}>
        <div className={"row row-foo"}>
          <div className={"col-md-6 col-lg-4 g-5 ql"}>
            <div className={"footer-wrapper"}>
              <h4>quick links</h4>
              <ul className={"footer-menu"}>
                {
                  FooterData.map((item, i) => (
                    <li key={i}>
                      <Link target={item.new} to={`${item.href}`}>{item.name}</Link>
                      {/* <a href="javascript:void(0)" onClick={()=>{navigate(`${item.href}`)}} target="_blank" rel="noreferrer">{item.name} </a> */}
                    </li>
                  ))
                }
              </ul>
            </div >
          </div >

          <div className={"col-12 col-lg-4 g-5"} >
            <div className={"footer-middle"} >
              <div className={"logo-area"} >
                <img src="/images/logo/mho-logo-white.png" alt="Myhealthopinions" />

                <ul className={"social-media"} >
                  {
                    MiddleData.map((item, i) => (
                      <li key={i}>
                        <a href={item.href} target="_blank" rel="noreferrer">
                          <i className={item.logo}></i>
                        </a>
                      </li >
                    ))
                  }
                </ul >
              </div >
            </div >
          </div >

          <div className={"col-md-6 col-lg-4 g-5 cn"} >
            <div className={"footer-wrapper footer-contact"} >
              <h4>Contact us</h4>
              <ul>
                {FooterContact.map((item, i) => (
                  <li key={i}>
                    {item?.p && <p>
                      <i className={item.i}></i>
                      {item.p}
                    </p>}
                    {item?.name &&
                      <a href={item.href}>
                        <i className={item.i}></i>{item.name}</a>}
                  </li>

                ))}
              </ul >
            </div >
          </div >
        </div >
      </div >

      <div className={"copyright"} >
        <p>Copyright 2023 &copy; Frequent Research Fieldwork Solutions Pvt. Ltd. (f/k/a MyHealthOpinions). All Rights Reserved.
        </p>
      </div >
    </footer >
  )
}

export default Footer