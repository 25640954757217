import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import api from '../../../modules/api';
import FrequentSlider from "../../slider/FrequentSlider";

const Testimonialsdata = [
	{
		p: "I have participated in health survey, just having basic detail those I have observed after my fathers heart surgery and this is most important to have some extra in your pocket. Thanks you :)",
		h6: "- Patricia Garci",
		h5: "USA",
	},
	{
		p: "I am under - graduate Nurse and usually spare some extra time to check the survey daily and now, I am addicted which is good for my extra bills.Much Appreciate",
		h6: "Edeline",
		h5: "Germany",
	},
	{
		p: "I feel they offer great promotions and reward their users. Also have an amazing customer service with timely responses and great follow through. Thank you",
		h6: "James",
		h5: "USA",
	},

	{
		p: "I pariticipate in last month.Health opinion panel have good source of information covering most of the healthcare policies till now.",
		h6: "Dr Emma Noah",
		h5: "New York",
	},
	{
		p: "I feel they offer great promotions and reward their users. Also have an amazing customer service with timely responses and great follow through. Thank you",
		h6: "James",
		h5: "USA",
	},
	{
		p: "Great platform to earn extra, great support team to assist quickly and Glad to have more survey soon.",
		h6: "Danya Milch",
		h5: "Moscow",
	},
	{
		p: "Very quick response team to support, Quick reward redemption. Cheersssss",
		h6: "Charlie Charlotte",
		h5: "London",
	},
	{
		p: "Appriciable work by My Health Opinion Team. These type of Panels helps doctor to read the analysis more efficiently.",
		h6: "Dr Anil Jain",
		h5: "India",
	},
	{
		p: "I’m a housewife with no-work. I have registered myself just few coupld of month back and start participating in paid online surveys and earn a little extra money and help to pay for my household expenses and to treat my family special things. Thanks my health opinion",
		h6: "Wandy Doah",
		h5: "Germany",
	},
	{
		p: "Experience some downtime during surveys but it's great to have more survey done now. Health Opinion support team helps really quick. Much Appreciate health opinion site",
		h6: "Fengge",
		h5: "China",
	},
	{
		p: "This is one of the best site who offer the real money otherwise I was fade up with fake advertisements. Threshold reward is realy quick to achive and redeem it thru PayPal. Thank a lot support.",
		h6: "Saada",
		h5: "Thailand",
	},
];
const TestimonialSliderSlides = () => {
	const testimonial = Testimonialsdata.map((item) => (
		<div key={item._id} className={"slider-wrapper"} data-aos="fade-up">
			<div className={"quote"}>
				<i className={"fa fa-quote-left"}></i>
			</div>
			<p>{item.p}</p>
			<h6>{item.h6}</h6>
			<h5>{item.h5}</h5>
		</div>
	));
	return <FrequentSlider responsive={false}>{testimonial}</FrequentSlider>;
};

export default function TestimonialSlider() {
	return (
		<section className={"testimonial-section sec-padding"} id="testimonial">
			<div className={"container"}>
				<div className={"row"}>
					<div className={"col-md-12"}>
						<div className={"sec-head text-center"}>
							<h3 className={"text-uppercase font-bold"}>Testimonials</h3>
						</div>
						<div className={"testimonial-slider"}>
							<TestimonialSliderSlides />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
