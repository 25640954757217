import React from 'react'
import Header from '../../components/visitor/Header'
import Footer from '../../components/visitor/Footer'

export default function Survey(){
    return (
        <>
        <Header />
        <Footer />
        </>
    )
}