import React from 'react'
import DashHeader from '../../components/common/DashHeader'
import DashAside from '../../components/common/DashAside'

const Settings = () => {
  return (
    <>
      <div id="dashboard">
        <div className={"wrapper"}>
          <DashHeader />
          <DashAside />
          <main>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="title">
                      Change Password
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-wrapper">
                      <form action="/user/my/settings" method="patch">
                        <div className="row">
                          <input type="hidden" name="email" value="uttam.kumar@team.frequentresearch.com" />
                          <input type="hidden" name="action" value="changepassword" />
                          <frfs-password className="col-sm-12" label="Old password" name="curpass"></frfs-password>
                          <frfs-password className="col-sm-12" label="New password" name="newpass" complex="4"></frfs-password>
                          <frfs-password className="col-sm-12" label="Confirm password" name="cnfpass" parent="newpass"></frfs-password>
                          <div className="col-sm-12 flex-right">
                            <button type="button" className="btn btn-primary" onClick={() => { console.log("validateAndSubmit(this)") }}>Change password</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="title">
                    Survey Notification
                  </div>
                </div>
                <div className="card-body">
                <div className="form-wrapper">
                  <form action="/user/my/settings" method="patch" className="row">
                    <input type="hidden" name="email" value="uttam.kumar@team.frequentresearch.com" />
                    <input type="hidden" name="action" value="notifications" />
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="">
                          How do you like to be notified for new survey
                        </label>
                        <div className="options" is="frfs-notify">
                          <div className="input">
                            <input type="checkbox" name="notifyemail" id="byemail" value="1" />
                            <label htmlFor="byemail">
                              By E-Mail
                            </label>
                          </div>
                          <div className="input">
                            <input type="checkbox" name="notifysms" id="bysms" />
                            <label htmlFor="bysms">
                              By SMS (Short Message Service)
                            </label>
                          </div>
                          <div className="input">
                            <input type="checkbox" name="notifyphone" id="byphone" />
                            <label htmlFor="byphone">
                              By Phone Call
                            </label>
                          </div>
                          <div className="input">
                            <input type="checkbox" name="notifynotifications" id="bynotifications" />
                            <label htmlFor="bynotifications">
                              By Dashboard Notifications
                            </label>
                          </div>
                          <div className="input">
                            <input type="checkbox" name="notifywhatsapp" id="bywhatsapp" />
                            <label htmlFor="bywhatsapp">
                              By Whatsapp
                            </label>
                          </div>
                          <div className="input" hidden >
                            <div className="whatsappform">
                              <frfs-tel cn="" label="I confirm the below no. is on whatsapp" name="whatsappnum" value="" editable="false"></frfs-tel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 flex-right">
                      <button type="button" className="btn btn-primary" onClick={() => { console.log("validateAndSubmit(this)") }}>
                        Save and Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="title">
                    Terms and conditions
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-wrapper">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="logo">
                            <img src="/images/logo/mho-logo.png" alt="myhealthopinions" />
                          </div>
                          <div className="terms">
                            <h2>
                              We Value about your Data Privacy
                            </h2>

                            <p>
                              We value and care about the privacy of your personal information and can ensure with our data privacy policy
                              your personal data will be only used for the Market Research purpose only and will be secure and safe with
                              us.Your data will not be shared to any third party vendor and will make sure to limit your sensitive data
                              privacy during data collection in any in-house or third party survey.
                            </p>
                            <p>
                              You would be able to get more opportunities to participate in your preferable survey categories. Your opinion
                              will help our clients to take better decisions for their business and you will be able to earn more and more
                              rewards.
                            </p>
                            <h2>
                              My Health Opinion Frequent General Consent Form
                            </h2>
                            <p>
                              MyHealthOpinions and our parent company, Frequent Research Fieldwork Solutions Private Limited, value your data
                              inputs and your data privacy is matters for us. Once you login, MyHealthOpinions will collect your personal
                              information and will use your personal data inputs to deliver our research services, websites and applications
                              (collectively, our "Services''). This Consent helps you to learn How, What and Why we use your personal data.
                              Our Consent form enables you to describe the access, update or otherwise control of your personal data that we
                              process for our research purposes. Your personal data may include information such as:

                            </p>
                            <ul>
                              <li>
                                Name

                              </li>
                              <li>
                                Location

                              </li>
                              <li>
                                Telephone number

                              </li>
                              <li>
                                Date of birth

                              </li>
                              <li>
                                Email address

                              </li>

                            </ul>
                            <h2>
                              WHAT ADDITIONAL INFORMATION DO WE COLLECT?

                            </h2>
                            <ul>
                              <li>
                                Behaviour Tracking

                              </li>
                              <li>
                                Cookies History

                              </li>
                            </ul>
                            <ul className="starred">
                              <li>To activate your account you must accept to the Terms and Conditions and Privacy Notice</li>
                              <li>To have access of more available surveys you must accept to participate in surveys and other market research
                                activities</li>
                            </ul>
                            <div className="terms-list">

                              <div className="top-field">
                                <div className="form-field">
                                  <input type="checkbox" onClick={() => { console.log('checkall(this)') }} style={{ display: "none" }} id="tnc-1" />
                                  <label htmlFor="tnc-1">
                                    Accept all
                                  </label>
                                </div>
                              </div>
                              <form action="/user/my/settings" method="patch" className="terms-checkbox">
                                <input type="hidden" name="email" value="uttam.kumar@team.frequentresearch.com" />
                                <input type="hidden" name="action" value="terms" />
                                <div className="form-field-list">
                                  <div className="form-field">
                                    <input value="true" type="checkbox" required name="dataPrivacy" style={{ display: "none" }} id="tnc-2" />
                                    <label htmlFor="tnc-2">
                                      I accept to the
                                      <a href='/page/privacy' target="_blank">
                                        Privacy Policy &amp; Terms and Conditions</a>
                                      * (required)
                                    </label>
                                  </div>
                                  <div className="form-field">
                                    <input value="true" type="checkbox" required name="surveyParticipation" style={{ display: "none" }} id="tnc-3" />
                                    <label htmlFor="tnc-3">
                                      I accept to participate in surveys and other market research opinion activities * (required)
                                    </label>
                                  </div>
                                  <div className="form-field">
                                    <input value="true" type="checkbox" required name="cookiesAccept" style={{ display: "none" }} id="tnc-4" />
                                    <label htmlFor="tnc-4">
                                      I accept to have MyHealthOpinions and it's trusted third parties to use cookies to improve your experience and to personalize content for market research * (required)
                                    </label>
                                  </div>
                                  <div className="form-field">
                                    <input value="true" type="checkbox" name="dataSensitivity" style={{ display: "none" }} id="tnc-5" />
                                    <label htmlFor="tnc-5">
                                      I accept to share my sensitive data for market research purposes
                                    </label>
                                  </div>
                                  <div className="form-field">
                                    <input value="true" type="checkbox" name="profileShare" style={{ display: "none" }} id="tnc-6" />
                                    <label htmlFor="tnc-6">
                                      I accept to share my profile information with third-parties for market research purposes
                                    </label>
                                  </div>
                                  <div className="form-field">
                                    <input value="true" type="checkbox" name="mobileAdvertising" style={{ display: "none" }} id="tnc-7" />
                                    <label htmlFor="tnc-7">
                                      I accept to the data collection and to share of my mobile advertising identifiers or other identifiers
                                    </label>
                                  </div>
                                  <div className="form-field">
                                    <input value="true" type="checkbox" name="deviceID" style={{ display: "none" }} id="tnc-8" />
                                    <label htmlFor="tnc-8">
                                      I accept to the use of cookies and the collection of my device ID information for market research purposes
                                    </label>
                                  </div>
                                  <div className="form-field flex-end">
                                    <button id="termsButton" type="button" className="btn btn-secondary btn-disabled fr" title="Accept at least 5 terms to proceed">Update</button>
                                    <input type="hidden" name="userid" value="64e4aa41b9ab97723223610c" />
                                  </div>
                                </div>

                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default Settings