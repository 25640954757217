import React from 'react'
import Image from '../../common/Image'

const Rewarddata = [
    {
        "img":"Rewards-PayPal.png",
        "name": "Paypal"
    },
    {
        "img":"Rewards-upi.png",
        "name": "Upi"
    }, {
        "img":"Rewards-wire-transfer.png",
        "name": "Wire Transfer"
    }, {
        "img":"Rewards-medical-report.png",
        "name": "Medical Report"
    },
]

const RewardList = () => {
    return (
        <>
            {Rewarddata.map(item => (
                <div key={item.name} className={"col-sm-6 col-md-3"}>
                    <div className={"item"}>
                        <div className={"box"} data-aos="fade-up" data-aos-duration="1000" >
                            <div className={"image-wrapper"} >
                                <Image img={`/images/rewards/${item.name}.png`} alt={item.name} />
                            </div>
                            <div className={"box-content box-content-alignment"} >
                                <h5>{item.name}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default function Rewards(){
    return (<section className={"Rewards-mode sec-padding"} >
    <div className={"container"} >
        <div className={"row"} >
            <div className={"col-12"} >
                <div className={"sec-head text-center"} data-aos="fade-up" data-aos-duration="1000" >
                    <h3 className={"font-bold text-uppercase"}>Rewards Mode</h3>
                </div >
            </div >
        </div >
        <div className={"content-wrapper"}>
            <div className={"row"}>
                <RewardList />
            </div>
        </div>
    </div >
</section >)
}
