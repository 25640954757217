import React from "react"
import { Route, Routes } from "react-router-dom"
import PrivateRoute from "./PrivateRoute"
import {
    Home,
    Contact,
    Register,
    Policy,
    FAQ,
    Survey,
    Cookies,
    Privacy,
    GDPR,
    ProfilingAndQuality,
    WorkProgressPage,
    ResetPassword,
    EmailVerification,
    Dashboard,
    MyAccount,
    Profiles,
    Referals,
    Rewards,
    Settings,
    Support,
    SurveyList
} from "../pages"

export const AllRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/register/:role" element={<WorkProgressPage />} />
                <Route path="/survey/:survey" element={<Survey />} />
                <Route path="/policy/policies" element={<Policy />} />
                <Route path="/policy/cookies" element={<Cookies />} />
                <Route path="/policy/privacy" element={<Privacy />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/profiling" element={<ProfilingAndQuality />} />
                <Route path="/policy/gdpr" element={<GDPR />} />
                <Route path="/reset/:key" element={<ResetPassword />} />
                <Route path="/pending" element={<WorkProgressPage />} />
                <Route path="/user/verify/:key" element={<EmailVerification />} />
                <Route path="/dashboard" element={
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                } />
                <Route path="/my-account" element={
                    <PrivateRoute>
                        <MyAccount />
                    </PrivateRoute>
                } /> <Route path="/Profiles" element={
                    <PrivateRoute>
                        <Profiles />
                    </PrivateRoute>
                } />
                <Route path="/rewards" element={
                    <PrivateRoute>
                        <Rewards />
                    </PrivateRoute>
                } />
                <Route path="/referals" element={
                    <PrivateRoute>
                        <Referals />
                    </PrivateRoute>
                } />
                <Route path="/surveyslist" element={
                    <PrivateRoute>
                        <SurveyList />
                    </PrivateRoute>
                } />
                <Route path="/settings" element={
                    <PrivateRoute>
                        <Settings />
                    </PrivateRoute>
                } />
                <Route path="/support" element={
                    <PrivateRoute>
                        <Support />
                    </PrivateRoute>
                } />
            </Routes>
        </>
    )
}
