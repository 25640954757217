import React from 'react'
import Joyride from 'react-joyride';
const steps = [
    {
        target: '#dashboard-aside',
        content: 'Dashboard Sidebar',
        disableBeacon: true,
    },
    {
        target: '.profile-area',
        content: 'Profile Area',
    }, {
        target: '.survey-slider',
        content: 'This another awesome feature!',
    }, {
        target: '#payment-list',
        content: 'This another awesome feature!',
    },
]

const Tour = () => {
    const [showTour, setShowTour] = React.useState(false)
    React.useEffect(() => {
        const tourCompleted = localStorage.getItem('tourstatus')
        if (!tourCompleted) {
            setShowTour(true)
            localStorage.setItem('tourstatus',true)
        }

    }, [])

    return (
        <>

            <Joyride
                steps={steps}
                continuous={true}
                showProgress={true}
                showSkipButton={true}
                run={showTour}
                disableScrolling={true}
            />
        </>
    )
}

export default Tour