import React from 'react'

const profiledata = [
    {
        "img": "Dashboard-reward.png",
        "alt":"reward",
        "p": "total rewards earned",
        "h5": "370 pts",

    }, {
        "img": "Dashboard-money-bag.png",
        "alt":"money-bag",
        "p": "total rewards claimed",
        "h5": "$ 0 USD"
    }, {
        "img": "Dashboard-man.png",
        "alt":"man",
        "p": "total profile completion",
        "h5": "31%"
    }, {
        "img": "Dashboard-online-survey.png",
        "alt":"online-survey",
        "p": "total survey taken",
        "h5": "1"
    }, {
        "img": "Dashboard-completed-task.png",
        "alt":"completed-task",
        "p": "completed surveys",
        "h5": "1"
    }, {
        "img": "Dashboard-fake.png",
        "alt":"fake",
        "p": "disqualified surveys",
        "h5": "0"
    }, 
]

const Profilecard = () => {
    return (
        <>
            {profiledata.map((item, i) => (
                <div className="col-md-4" key={i}>
                    <div className="card sm-card">
                        <div className="card-body">
                            <div className="image-wrapper">
                                <div className="icon">
                                    <img src={`./images/Dashboard-Images/${item.img}`} alt={item.alt} />
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <p>{item.p}</p>
                                <h5>{item.h5}</h5>
                            </div>
                        </div>
                    </div>
                </div>

            ))}
        </>
    )
}

export default Profilecard