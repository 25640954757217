import React from 'react'
import DashHeader from '../../components/common/DashHeader'
import DashAside from '../../components/common/DashAside'
import Table from '../../components/common/Table'

const SurveyList = () => {
  return (
    <>
      <div id="dashboard">
        <div className={"wrapper"}>
          <DashHeader />
          <DashAside />
          <main>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <div className="title">Participation Surveys Lists</div>
                  </div>
                  <Table table={"Participation Surveys Lists"} />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default SurveyList