import React from 'react'
import {useParams} from "react-router-dom"
import Header from '../../components/visitor/Header'
import Footer from '../../components/visitor/Footer'

const Policy = () => {  
  return (
   <>
   <Header />
   <h1>Policy Page in underdevelopment</h1>
   <Footer />
   </>
  )
}

export default Policy