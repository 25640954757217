import React from 'react'

const DashHeader = () => {
    return (
        <>
            <div id="dashboard">
                <header>
                    <div className={"logo-area"}>
                        <div className={"desk"}>
                            <img src={`/images/logo/mho-logo.png`} alt="my health opinion" />
                        </div>
                        <div className={"mobile"}>
                        <img src={`/images/logo/mho-logo.png`} alt="my health opinion" />
                        </div>
                    </div>
                    <div className={"header-info"}>
                        <div className={"left"}>
                            <div className={"toggle"}></div>
                            <div className={"title"}>
                                <h4>dashboard</h4>
                            </div>
                        </div>
                        <div className={"right"}>
                            <div className={"search"}>
                                <input type="search" />
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </div>
                            <div className={"user"}></div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    )
}

export default DashHeader