import React from 'react'
import DashHeader from '../../components/common/DashHeader'
import DashAside from '../../components/common/DashAside'
import Profilecard from "../../components/Dashboard/Profilecard"
import Table from '../../components/common/Table'

const MyAccount = () => {
    const [status, setStatus] = React.useState("pid1")
    const handleStatus = (payload) => {
        setStatus(payload)
    }
    return (
        <>
            <div id="dashboard">
                <div className={"wrapper"}>
                    <DashHeader />
                    <DashAside />
                    <main>
                        <div className="row profile-top-row">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="profile-header">
                                        <h5 className="text-capt">welcome back</h5>
                                        <p className="text-capt">sharina</p>
                                        <img src="./images/Dashboard-Images/Dashboard-profile-icon.png" alt="profile icon" />
                                    </div>
                                    <div className="card-body">
                                        <div className="profile-bottom">
                                            <div className="image-wrapper">
                                                <div className="left">
                                                    <img src="./images/Dashboard-Images/Dashboard-avatar.jpg" alt="avatar" />
                                                </div>
                                                <div className="right">
                                                    <p>Created on 11-Mar-2023</p>
                                                    <ul>
                                                        <li><i className="fa-solid fa-star"></i></li>
                                                        <li><i className="fa-solid fa-star"></i></li>
                                                        <li><i className="fa-solid fa-star"></i></li>
                                                        <li><i className="fa-solid fa-star"></i></li>
                                                        <li><i className="fa-solid fa-star"></i></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="content-wrapper">
                                                <ul>
                                                    <li><span>370 pt</span>
                                                        <p>current rewards</p>
                                                    </li>
                                                    <li><span>$ 3.7</span>
                                                        <p>conversion</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="title">my profile</div>
                                        <div className="icon">
                                            <a href={void (0)}><i className="fa-solid fa-pen-nib"></i></a>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="profile-info">
                                            <ul>
                                                <li>
                                                    <span>address</span>
                                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                                                    <div className="map">

                                                    </div>
                                                </li>
                                                <li>
                                                    <span>email</span>
                                                    <p>info@gmail.com</p>
                                                </li>
                                                <li>
                                                    <span>mobile</span>
                                                    <p>+ 202-555-2828</p>
                                                </li>
                                                <li>
                                                    <span>Birth Date</span>
                                                    <p>October 22th, 1990</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="profile-social-media">
                                            <ul>
                                                <li><i className="fa-brands fa-linkedin-in"></i></li>
                                                <li><i className="fa-brands fa-facebook-f"></i></li>
                                                <li><i className="fa-brands fa-instagram"></i></li>
                                                <li><i className="fa-brands fa-youtube"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row">
                                <Profilecard />
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="tab">
                                                <ul>
                                                    <li><a onClick={() => { handleStatus('pid1') }} className={status === "pid1" ? "tab_href activ" : "tab_href"} href={void (0)}>rewards summary</a></li>
                                                    <li><a onClick={() => { handleStatus('pid2') }} className={status === "pid2" ? "tab_href activ" : "tab_href"} href={void (0)}>rewards processed summary</a></li>
                                                    <li><a onClick={() => { handleStatus('pid3') }} className={status === "pid3" ? "tab_href activ" : "tab_href"} href={void (0)}>rejected responses</a></li>
                                                </ul>
                                            </div>
                                            <div id="pid1" className="box3_main a1" style={status === "pid1" ? null : { display: "none" }}>
                                                <Table />
                                            </div>
                                            <div id="pid2" className="box3_main a1" style={status === "pid2" ? null : { display: "none" }}>
                                                <Table />
                                            </div>
                                            <div id="pid3" className="box3_main a1" style={status === "pid3" ? null : { display: "none" }}>
                                                <Table />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div >
            </div >
        </>
    )
}

export default MyAccount;