import React from 'react'
import FrequentSlider from '../slider/FrequentSlider'
import Image from '../common/Image'
const Data = [
    {
        "Heading": "Welcome survey",
        "Details": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed, sit?",
        "message": "Welcome surver"
    }, {
        "Heading": "Welcome survey",
        "Details": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed, sit?",
        "message": "Welcome surver"
    }, {
        "Heading": "Welcome survey",
        "Details": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed, sit?",
        "message": "Welcome surver"
    },
]
const SurveySlider = () => {
    return (
        <>
            <div>
                <FrequentSlider slides={2}>
                    {Data.map((item) => {
                        return (
                            <div className="item">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="survey-card">
                                            <div className="image-wrapper">
                                                <Image img={`/images/Dashboard-Images/${item.Heading}.png`} alt={item.name} />
                                            </div>
                                            <div className="content-wrapper">
                                                <h6>{item.Heading}</h6>
                                                <p>{item.Details}</p>
                                                <a href={void (0)} className="btn btn-primary">
                                                    {item.Heading}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </FrequentSlider>
            </div>
        </>
    )
}

export default SurveySlider