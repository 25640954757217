import React from 'react';
import Image from '../../common/Image';

export default function HomeAbout(){
    return (<section className={"about-section sec-padding"} >
    <div className={"container"}>
        <div className={"row"}>
            <div className={"col-md-6"}>
                <div className={"content-wrapper"} data-aos="fade-up" data-aos-duration="1000" >
                    <div className={"sec-head"} >
                        <h3 className={"text-uppercase font-bold"}> About Us</h3 >
                    </div >
                    <div className={"inner"} >
                        <h4>Welcome to My Health Opinions</h4>
                        <p>A healthcare community of Frequent Research which covers solely healthcare audiences responses which is used by us for healthcare sponsors and other pharmaceutical research purpose only including Physicians, Healthcare Professionals and Consumer Ailments.</p>
                        {/* <a href={void(0)} className={"bttn primary-bttn"}> read more
                            < span className={"lnr lnr-arrow-right"} ></span >
                        </a > */}
                    </div >
                </div >
            </div >
            <div className={"col-md-6"}>
                <div className={"image-wrapper"}>
                    <Image img="/images/home/about/banner1.jpg" alt="About us" />
                </div >
            </div >
        </div >
    </div >
</section >)
}