import React, { useState } from 'react'
import Header from "../../components/visitor/Header";
import Footer from "../../components/visitor/Footer";
import Sign from "../../components/visitor/popup/Sign";


const ProfilingAndQuality = () => {
    const [showSign, setShowSign] = useState(false);

    return (
        <>
            <Header />
            <section class="inner-banner">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center">
                            <div class="content text-center">
                                <h3 className="text-capitalize font-bold">Profiling And Quality Score</h3>
                                <ul className="d-flex justify-content-center mt-4">
                                    <li>
                                        <a href="/">home</a>
                                    </li>
                                    <li>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </li>
                                    <li>
                                        <p>Profiling And Quality Score</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-padding bg-white content-page quality-standard">
                <div class="container">
                    <div class="row">
                        <div className="col-12">
                            <h3>Profiling Standard</h3>
                            <h6><strong>Our	Profiling	scoring	system	designed	to	measure	individual
                                survey	respondent	quality	on	a	scale	of	0-200.</strong></h6>
                            <ul>
                                <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam aperiam ipsum laudantium repellat magnam temporibus similique iure repellendus dicta, dolores perferendis, assumenda magni! Molestiae ratione esse eaque eveniet. Voluptatibus nihil labore consectetur magnam hic repellendus, rem ducimus aliquid modi eos quas, molestiae exercitationem velit reiciendis sapiente vel natus accusantium architecto nostrum tenetur accusamus adipisci! Magni dolorem harum commodi recusandae, fugiat veniam et sunt alias quibusdam, ratione neque voluptates eaque nemo aliquam totam a, fugit culpa autem soluta cum sit. Explicabo totam et voluptatem enim dolor non perspiciatis dolorem cumque dignissimos ratione officiis vel possimus suscipit, nihil deleniti reiciendis ut doloribus soluta? Minima esse aspernatur repellendus, in ducimus placeat officiis autem porro sapiente, similique commodi aut accusantium. Ducimus totam doloremque rerum.</li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi explicabo accusantium nostrum amet. Repellat blanditiis eveniet ab nemo ullam eaque expedita corrupti, quam, facilis placeat accusamus qui a nam ratione fuga sapiente quia nobis perferendis porro? Voluptates harum ipsa, distinctio iusto libero sequi sapiente minima ut, consequatur illo sint nostrum molestias consectetur quis cum. Sit repudiandae perspiciatis cupiditate corporis aperiam tempore repellat sequi esse asperiores consequuntur iusto ipsum voluptatem recusandae delectus fuga soluta dolores nostrum eaque, officiis minima aut, reprehenderit adipisci! Magnam facere harum quos corrupti eius, veritatis voluptatem nobis natus quibusdam nemo reprehenderit rem vel reiciendis consectetur eveniet porro.</li>
                            </ul>

                        </div>
                        <div className="col-12 col-lg-6 g-5">
                            <div className="profiling-img-wrapper">
                                <img src="images/profiling/1.png" alt="" />
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 g-5">
                            <div className="profiling-img-wrapper">
                                <img src="images/profiling/2.png" alt="" />
                            </div>
                        </div>

                        <div className="col-12">
                            <ul>
                                <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam aperiam ipsum laudantium repellat magnam temporibus similique iure repellendus dicta, dolores perferendis, assumenda magni! Molestiae ratione esse eaque eveniet. Voluptatibus nihil labore consectetur magnam hic repellendus, rem ducimus aliquid modi eos quas, molestiae exercitationem velit reiciendis sapiente vel natus accusantium architecto nostrum tenetur accusamus adipisci! Magni dolorem harum commodi recusandae, fugiat veniam et sunt alias quibusdam, ratione neque voluptates eaque nemo aliquam totam a, fugit culpa autem soluta cum sit. Explicabo totam et voluptatem enim dolor non perspiciatis dolorem cumque dignissimos ratione officiis vel possimus suscipit, nihil deleniti reiciendis ut doloribus soluta? Minima esse aspernatur repellendus, in ducimus placeat officiis autem porro sapiente, similique commodi aut accusantium. Ducimus totam doloremque rerum.</li>
                                <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi explicabo accusantium nostrum amet. Repellat blanditiis eveniet ab nemo ullam eaque expedita corrupti, quam, facilis placeat accusamus qui a nam ratione fuga sapiente quia nobis perferendis porro? Voluptates harum ipsa, distinctio iusto libero sequi sapiente minima ut, consequatur illo sint nostrum molestias consectetur quis cum. Sit repudiandae perspiciatis cupiditate corporis aperiam tempore repellat sequi esse asperiores consequuntur iusto ipsum voluptatem recusandae delectus fuga soluta dolores nostrum eaque, officiis minima aut, reprehenderit adipisci! Magnam facere harum quos corrupti eius, veritatis voluptatem nobis natus quibusdam nemo reprehenderit rem vel reiciendis consectetur eveniet porro.</li>
                                <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est et earum labore quod, repellendus ullam natus iusto suscipit, itaque reiciendis consequuntur eligendi non, optio dicta adipisci! Eveniet alias inventore, reiciendis ullam non numquam sequi provident cupiditate dicta magnam distinctio aliquid vitae tenetur maiores voluptas totam saepe veritatis fugiat! Enim incidunt consectetur optio velit explicabo dolor, expedita voluptas. Tenetur, cum, incidunt quisquam, tempora amet facilis nam ullam perferendis maxime consequatur accusamus velit fugiat sint nulla laudantium debitis harum. Quaerat.</li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="bot-validation">
                        <div className="row">
                            <div className="col-12">
                                <h3>Bot Validation</h3>
                                <ul>
                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam, quasi eos. Nihil commodi pariatur laboriosam quo consequuntur minima veritatis in delectus quaerat praesentium esse, hic voluptatem voluptates, aliquid deleniti eaque nostrum laborum architecto dignissimos reprehenderit. Facere eveniet asperiores fugit, fuga cumque in ipsum incidunt molestiae?</li>
                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi, est, consectetur, culpa placeat totam sapiente praesentium minima aperiam accusamus corporis odio nesciunt nobis nulla minus quo ipsum labore ex omnis! Consequuntur, id.</li>
                                </ul>
                            </div>

                            <div className="col-12">
                                <div className="profiling-img-wrapper">
                                    <img src="images/profiling/3.png" alt="" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="profiling-img-wrapper">
                                    <img src="images/profiling/4.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gat-survey">
                        <div className="row">
                            <div className="col-12">
                                <h3>GATE Survey, Pre-screener & Other Security	Checks</h3>
                                <ul>
                                    <li>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto placeat eaque nesciunt, magnam tempore odit quaerat voluptates! A sit assumenda esse earum voluptas, ipsam repudiandae aliquid nisi, molestiae illum ad.</li>
                                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint excepturi possimus omnis, quos error quam corporis soluta aliquam perspiciatis similique quasi tenetur, iure optio saepe delectus quidem? Neque ut repudiandae iure, blanditiis corporis enim!</li>
                                </ul>
                            </div>

                            <div className="col-12">
                                <div className="profiling-image-wrapper">
                                    <img src="images/profiling/5.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='section-padding quality-standard content-page'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>QUALITY STANDARD</h3>
                            <h6><strong>Our panel structure forms the perfect basis for the choice of your random samples. By way of guided invitation of study participants via quota plans or optimal sampling strategies, we are able to collect data exactly according to your ideas.</strong></h6>
                            <p>As a panel provider, Frequent Research is an ISO 9001:2015,ISO 27001: 2015. ISO 20252: 2019 Certified company and ensures the quality of the online questionnaires with comprehensive measures for panel cultivation:</p>
                            <ul>
                                <li>Regular updating of abundant master data for all panellists</li>
                                <li>Meticulous duplicate cleansing via automated processes and manual review</li>
                                <li>Regular suspension of inactive panel participants</li>
                                <li>Tracking of individual fill-out time, pattern respondents (e.g. straight-lining), answer quality and quantity in open questions</li>
                                <li>Systematic detection of untrustworthy survey participants, and exclusion of these panellists</li>
                                <li>Proactive and sustainable panellist support and panellist helpline with answer guarantee</li>
                                <li>Panellists can participate in a maximum of 3 surveys per month</li>
                            </ul>

                            <h6><strong>Frequent set standards and leave no room to employ cutting edge technology and always find new ways and work using different techniques and methodologies to ensure data quality</strong></h6>
                            <p>We care about details creating valid and robust outcomes therefore invest in technology and ensure to deliver high quality data to our researchers & clients. We have our inhouse QC team and partnered with many third party companies who are industry experts in security checks at the time of recruitment and at various survey levels. Our high quality standards in recruitment of participants and our qualified team's continuous and meticulous maintenance of our panels can be seen in our exceptionally high response rates and the close affinity we enjoy with our participants. This results in responses that meet the highest standards of quality to provide our customers with a solid basis for successful research projects.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            {/* 1 */}
                            <div className="quality-card">
                                <div className="img-icon">
                                    <img src="/images/profiling/double-opt.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Double Opt-In</h2>
                                    <p>Our respondents follow a double opt-in procedure whenever they sign up. We then strengthen the budding relationship with these new members through a clever retention programme, based on attention, trust and honesty.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            {/* 2 */}
                            <div className="quality-card">
                                <div className="img-icon">
                                    <img src="/images/profiling/postal.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Email & Postal
                                        Address
                                        Verifications</h2>
                                    <p>We verify the registrant’s postal address and zip/postal code from a
                                        current local address directory and our panel recruitment team check
                                        through our database to ensure the email address is unique (all registrants
                                        must verify their email address through a double opt-in registration
                                        process)</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            {/* 3 */}
                            <div className="quality-card">
                                <div className="img-icon">
                                    <img src="/images/profiling/geoip.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>GeoIP and Unique
                                        IP Check</h2>
                                    <p>Our servers locate the registrant’s country location through his/her IP
                                        address and determines their eligibility for registration based on country
                                        specific rules.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            {/* 4 */}
                            <div className="quality-card">
                                <div className="img-icon">
                                    <img src="/images/profiling/unique-recruitment.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Unique Recruitment
                                        Process</h2>
                                    <p>We have a unique & rigorous registration process to validate new
                                        members, and continuous profile updates through periodic survey
                                        screeners. Our proprietary panels are profiled across a wide range of
                                        criteria that is including different profile and demographics, users of
                                        fast-moving consumer good, durables, automotive, telecommunications,
                                        decision makers ITDMs/BDMS, finance/insurance, healthcare and
                                        more. </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            {/* 5 */}
                            <div className="quality-card">
                                <div className="img-icon">
                                    <img src="/images/profiling/finger-digital.jpg" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Finger Digital Printing
                                        Imperium’s RelavantID</h2>
                                    <p>Our Custom Digital Fingerprint system identify each respondent and
                                        prevent fraud. This eliminates duplicate respondents and ensures
                                        survey data is unique to each participant. We also use TrueSample,
                                        Radware and RelevantID digital finger technology which ensures unique
                                        respondents in our panel. RelevantID, identify and blocks duplicate
                                        respondents real time while entering a survey..</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            {/* 6 */}
                            <div className="quality-card">
                                <div className="img-icon">
                                    <img src="/images/profiling/proxy.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Proxy Detection</h2>
                                    <p>Our servers locate the registrant’s country location through his/her IP
                                        address and determines their eligibility for registration based on
                                        country specific rules.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            {/* 7 */}
                            <div className="quality-card">
                                <div className="img-icon">
                                    <img src="/images/profiling/bot.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>BOT
                                        Prevention</h2>
                                    <p>We are integrated with Distil Networks BOT detection technology to
                                        eliminate BOT traffic before they even hit our servers. This helps us to
                                        stop online frauds, cybersecurity threats and ensure only humans are
                                        getting into our servers and surveys..</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section-padding content-page why-choose'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 g-5">
                        <h3>Top Reasons to Choose Us?</h3>

                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 1 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/flawless-delivery.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Flawless Delivery</h2>
                                    <p>We ensure Quality data, on time and on
                                        budget. Our team deliver insights at the
                                        speed of business; your business doesn’t
                                        have time to wait. We provide solutions to
                                        meet your expectations</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 2 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/flexible-pricing.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Flexible and Competitive Pricing</h2>
                                    <p>Our pricing strategy is highly flexible &
                                        competitive in the market, without
                                        compensating on the quality and the
                                        timeline of project delivery</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 3 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/global-coverage.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Global Coverage 90+ Regions</h2>
                                    <p>We provide all the opinions you need using
                                        the right mode every time with global
                                        reach in 90+ regions. We make your Reach
                                        to consumer and business targets online
                                        with the ability to select from over 800+
                                        profiles; multiple demographic and
                                        geographic options</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 4 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/real.png" alt="" />

                                </div>
                                <div className="content">
                                    <h2>Real, Valid & Responsive
                                        Respondents</h2>
                                    <p>We have access to a selected real ,
                                        responsive and valid number of panel
                                        members who are carefully screened to
                                        ensure quality real time data to our clients</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 5 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/quick-turnaround.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Quick Turnaround</h2>
                                    <p>Our Team work 24*6 and having dedicated
                                        team of account & Project managers who
                                        serves as one point of contact to provide
                                        quick turnaround and ensure the smooth
                                        execution of all your research projects</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 6 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/advanced-technology.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Latest Advance Technology</h2>
                                    <p>We always keep our foot ahead to deliver
                                        the best practice to our clients. We always
                                        care about the personal and confidential
                                        information safety and quality which is why
                                        we move with new innovative latest
                                        technology adaption and security in our
                                        project management tools & Severs to
                                        provide our clients quality driven data</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 7 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/the-panelist.png" alt="" />

                                </div>
                                <div className="content">
                                    <h2>The Largest Asian,NA & EU5
                                        Business Decision Makers Panellist</h2>
                                    <p>We have a strong B2B presence in APAC Network
                                        markets with large profiling database for
                                        C-Suits, ITDMS, FDMS, HRDMS etc.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 8 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/international-research.png" alt="" />

                                </div>
                                <div className="content">
                                    <h2>International Research Standard</h2>
                                    <p>We comply with international standards
                                        set by the governing associations and
                                        adapt the latest innovations in the
                                        industry, providing a highly professional
                                        service.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 9 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/client-satisfaction.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Client Satisfaction</h2>
                                    <p>Client Satisfaction is our top priority.
                                        Maintaining a long term relationship with
                                        our clients is the key to our approach. We
                                        understand that additional support is
                                        required as the market is always volatile.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 10 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/high-data.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>High Data Quality and Reliability</h2>
                                    <p>We are committed in providing reliable and
                                        highly accurate data. We also have an
                                        excellent quality control system that
                                        validates the data collected and analyzed.
                                        The research conducted are tailored to the
                                        specific requirements of the client.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 11 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/high-response.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>High Response Rate</h2>
                                    <p>We have developed a rigorous & Unique
                                        registration process to validate new
                                        members, and continuous profile updates
                                        through periodic survey screeners that
                                        helps us to deliver higher response values
                                        to our clients</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 12 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/skilled-team.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Highly Skilled Professionals &
                                        Dedicated Panel Recruitment Team</h2>
                                    <p>Our Team are built with highly professional
                                        team, specializing in market research,
                                        business analytics, finance, traditional /
                                        digital marketing and data collection /
                                        analysis, with a strong research
                                        knowledge. Our expertise provide value
                                        added market intelligence and research
                                        services for both local and international
                                        clients.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 g-5">
                            {/* 13 */}
                            <div className="top-reason-card">
                                <div className="img-icon">
                                    <img src="images/profiling/footprint-coverage.png" alt="" />
                                </div>
                                <div className="content">
                                    <h2>Global Timeframe Footprint
                                        Coverage</h2>
                                    <p>We work on every time Zone. According to
                                        Time Zone, Auto Mail sent to the
                                        Respondents. Frequent Research connects
                                        you with trusted opinions in all timeframes
                                        to ensure the right results the first time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={"panel-section sec-padding"}>
                <div className={"container"}>
                    <div className={"row align-items-center"}>
                        <div className={"col-md-8"}>
                            <div className={"content-wrapper"}>
                                <h3>Lets Get Started</h3>
                                <p>
                                    Be our community member and get rewarded with points and
                                    credit paypal account after your loyal inputs
                                </p>
                            </div>
                        </div>
                        <div className={"col-md-4"}>
                            <div className={"content-wrapper d-flex justify-content-center"}>
                                <a
                                    className={"bttn secondary-bttn"}
                                    id="join-panel"
                                    onClick={() => {
                                        setShowSign(!showSign);
                                    }}
                                >
                                    {" "}
                                    join now
                                    <span className="angle-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                        <i class="fa-solid fa-angle-right"></i>
                                        <i class="fa-solid fa-angle-right"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ProfilingAndQuality