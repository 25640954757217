import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import Image from '../../common/Image';

const Sign = (props) => {
    const {showSignup,handleSignupToggle} = props
    const navigate = useNavigate();
    return (
        <div className={showSignup ? "popup-signup-form m-open" : "popup-signup-form"}>
            <div className={"signup-form"} >
            <div className={"cross-bttn"} onClick={() => { handleSignupToggle() }} ></div >
                <div className={"inner"} >
                <div className={"image-wrapper"}>
                    <Image alt="Authentication" img="/images/home/forms/login-type.jpg" />
                </div>
                    <div className={"content-wrapper"}>
                    <div className={"form-head"}>
                        <h3>Ready to Join Our Panel</h3>
                        <p>Please select your identity</p>
                    </div>
                        <form>
                        <div className={"login-type"}>
                            <div className={"doctor-type"}>
                                <label htmlFor="sign-doctor-type">
                                    <Image alt="doctor signup" img="/images/home/forms/doctor-type.png" />
                                </label>
                                <input onClick={()=>{navigate("/register/doctor"); handleSignupToggle()}} type="radio" name="login-type" id="sign-doctor-type" />
                                <label className={"cbx"} htmlFor="sign-doctor-type"></label>
                                <h4>Doctor</h4>
                            </div >
                            <div className={"patient-type"} >
                                <label htmlFor="sign-patient-type">
                                    <Image alt="patient signup" img="/images/home/forms/patient-type.png" />
                                </label>
                                <input onClick={()=>{navigate("/register/patient"); handleSignupToggle()}} type="radio" name="login-type" id="sign-patient-type" />
                                <label className={"cbx"} htmlFor="sign-patient-type" ></label >
                                <h4>Patient / Care taker</h4>
                            </div >
                        </div >
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sign