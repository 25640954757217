import React, { useContext, useEffect, useState } from "react";
import Login from "./popup/Login";
import Sign from "./popup/Sign";
import Quote from "./quote/Quote";
import { useNavigate } from "react-router-dom";
import { PopupContext } from "../../PopupContext";


const Header = () => {
  const navigate = useNavigate();
  const popup = useContext(PopupContext)
  const [visibility, setVisibility] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  const [hamburger, setHamburger] = useState(false)
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <>
      <header id={"site-header"}>
        <div>
          <Login
            {...popup}
          />
          <Sign {...popup} />
        </div>
        {/* <div className={"top-header"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-md-12 col-lg-6 d-lg-flex d-none"}>
              <div className={"header-contact"}>
                <ul>
                  <li>
                    <a href="mailto:bids@myhealthopinion.com">
                      <span></span>bids@myhealthopinion.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={"col-md-12 col-lg-6"}>
              <div className={"header-login justify-content-lg-end justify-content-center"}>
                <ul>
                  <li><a href={void (0)} onClick={() => setShowLogin(!showLogin)}><span>Login</span></a></li>
                  <li><a href={void (0)} onClick={() => setShowSign(!showSign)}><span>Join Our Panel</span></a></li>
                  <Sign showSign={showSign} setShowSign={setShowSign} /> 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        <div className={"bottom-header-wrapper"}>
          <div className={"bottom-header"}>
            <div className={"container-fluid header-containment"}>
              <div className={"row"}>
                <div className={"col-lg-3 col-9"}>
                  <div className={"logo-area"}>
                    <a href="/">
                      <img
                        src="/images/logo/logo-300w.png"
                        alt="Myhealthopinions"
                      />
                    </a>
                  </div>
                </div>
                <div className={"col-lg-9 col-3"}>
                  <div className={"menu-area"}>
                    <div className={`toggle-menu d-lg-none d-flex`}>
                      <span id="menu" className="hide" style={{ display: hamburger ? "none" : "" }}>
                        <i onClick={() => { setHamburger(true) }} className="fa-solid fa-bars"></i>
                      </span>
                      <span style={{ display: hamburger ? "" : "none" }}>
                        <i onClick={() => { setHamburger(false) }} className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                    <nav style={{ display: "none" }}>
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/#about">About us</a>
                        </li>
                        <li>
                          <a href="/#professionals">Our Professionals</a>
                        </li>
                        <li>
                          <a href="/#specialties">Our Specialties</a>
                        </li>
                        <li>
                          <a href="/#solutions">Solutions</a>
                        </li>
                        <li>
                          <a href="/#testimonial">Testimonial</a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              navigate("/contact");
                            }}
                          >
                            Contact
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              popup.handleLoginToggle()
                            }}
                            className="header-login"
                          >
                            Login
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className={"mobile-nav"} style={{ display: hamburger ? "" : "none" }}>
                  <nav>
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/#about" onClick={() => setHamburger(false)}>About us</a>
                      </li>
                      <li>
                        <a href="/#professionals" onClick={() => setHamburger(false)}>Our Professionals</a>
                      </li>
                      <li>
                        <a href="/#specialties" onClick={() => setHamburger(false)}>Our Specialties</a>
                      </li>
                      <li>
                        <a href="/#solutions" onClick={() => setHamburger(false)}>Solutions</a>
                      </li>
                      <li>
                        <a href="/#testimonial" onClick={() => setHamburger(false)}>Testimonial</a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            navigate("/contact");
                            setHamburger(false)
                          }}
                        >
                          Contact
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            popup.handleLoginToggle();
                            setHamburger(false)
                          }}
                          className="header-login"
                        >
                          Login
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id={"request-quote"} className={visibility ? "sticky-active" : null}>
          <a onClick={() => { popup.handleQuoteToggle() }}>Request a Quote</a>
          <Quote {...popup} />
        </div>
      </header>
      <div className={visibility ? "go-to-top-vis" : "go-to-top"} onClick={goToTop} id="topBtn">
        <i className="fa-solid fa-arrow-turn-up"></i>
      </div >
    </>
  );
};

export default Header;
