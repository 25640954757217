import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export default function PrivateRoute({ children }) {
	const location = useLocation();
	if (!isAuth()) {
		return <Navigate state={location.pathname} to={"/"} replace />;
	}

	return children;
}
export function isAuth() {
	const token = localStorage.getItem("token");
	return !!token;
}
