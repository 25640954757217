import React from 'react'
// Importing Components
import Footer from '../../components/visitor/Footer';
import Header from '../../components/visitor/Header'
// Importing Components


const Contact = () => {

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(e);
    }

    return (
        <>
            <Header />
            <section className={"contact section-padding bg-white"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-lg-4"}>
                            <div className={"box"}>
                                <div className={"icon"}>
                                    <i className={"fe fe-mail"}></i >
                                </div >
                                <h6>Email</h6>
                                <a href="mailto:support@frequentresearch.com">support@frequentresearch.com</a>
                            </div >
                        </div >
                        <div className={"col-lg-4"}>
                            <div className={"box"}>
                                <div className={"icon"}>
                                    <i className={"fe fe-phone-call"}></i >
                                </div >
                                <h6>phone</h6>
                                <a href="tel:+91 8130015743">+91 8130015743</a>
                            </div >
                        </div >
                        <div className={"col-lg-4"}>
                            <div className={"box"}>
                                <div className={"icon"}>
                                    <i className={"fe fe-map"}></i >
                                </div >
                                <h6>Address</h6>
                                <a href="https://goo.gl/maps/omWW4BDscx5fZpFdA" target="_blank" rel="noreferrer">Third Floor, B-96, Pushpanjali Enclave, Pitampura,
                                    Delhi 110034 India</a>
                            </div >
                        </div >
                    </div >
                </div >
            </section >
            <section className={"map-section"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-lg-6"}>
                            <div className={"form-section section-padding"}>
                                <form onSubmit={handleSubmit} action="/api/v2/contact" method="post">
                                    <div className={"form-wrapper"}>
                                        <div className={"row"}>
                                            <div className={"col-md-6"}>
                                                <div className={"form-field"}>
                                                        <frfs-text name="fn" label="First Name" editable pattern="^[a-zA-Z]+$" />
                                                </div>
                                            </div>
                                            <div className={"col-md-6"}>
                                                <div className={"form-field"}>
                                                        <frfs-text name="ln" label="Last Name" pattern="^[a-zA-Z]+$" editable />
                                                </div>

                                            </div>
                                            <div className={"col-md-6"}>
                                                <div className={"form-field"}>
                                                        <frfs-email name="email" label="Email" editable />
                                                </div>
                                            </div>
                                            <div className={"col-md-6"}>
                                                <div className={"form-field"}>
                                                        <frfs-tel cn="in" name="phone" label="Contact No" editable="true"  />
                                                </div>
                                            </div>
                                            <div className={"col-md-12"}>
                                                <div className={"form-field"}>
                                                    <frfs-editor name="message" label="Message" editable />
                                                </div>
                                            </div>
                                            <div className={"col-md-12"}>
                                                <div className={"form-group"}>
                                                    <button type="button" className={"bttn secondary-bttn"} >send</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div >
                                </form >
                            </div >
                        </div >
                        <div className={"col-lg-6"}>
                            <div className={"map-area"}>
                                <iframe 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.8222201626054!2d77.1112085!3d28.694964199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d055691b37e47%3A0xa652bd028161255c!2sFrequent%20Research%20Fieldwork%20Solutions%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1680177657397!5m2!1sen!2sin" 
                                style={{ border: "0" }} 
                                allowFullScreen="" 
                                loading="lazy" 
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Map"
                                ></iframe>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
            <Footer />

        </>
    )
}

export default Contact