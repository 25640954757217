import React from 'react'
import AOS from 'aos';
import "aos/dist/aos.css";
import FrequentSlider from '../../slider/FrequentSlider';
import Image from "../../common/Image";
const ProfessionalData = [
    {
        "img": "Professional-practical-nurse.jpg",
        "name": "Licensed Practical Nurse"
    }, {
        "img": "Professional-nurse-practitioner.jpg",
        "name": "Nurse Practitioner"
    }, {
        "img": "Professional-nursing-assistant.jpg",
        "name": "Nursing Assistant"
    }, {
        "img": "Professional-therapist.jpg",
        "name": "Therapist"
    }, {
        "img": "Professional-Top-Level-Management-Department-Specialist.jpg",
        "name": "Top Level Management Department Specialist"
    }, {
        "img": "Professional-Pharmacy-Department-Specialist.jpg",
        "name": "Pharmacy Department Specialist"
    }, {
        "img": "Professional-Blood-Circulation.jpg",
        "name": "Blood Circulation"
    }, {
        "img": "Professional-Maintenance-Department-Specialist.jpg",
        "name": "Maintenance Department Specialist"
    }, {
        "img": "Professional-Security-Department-Specialist.jpg",
        "name": "Security Department Specialist"
    }, {
        "img": "Professional-Personnel-Department-Specialist.jpg",
        "name": "Personnel Department Specialist"
    }, {
        "img": "Professional-Medical-Record-Department-Specialist.jpg",
        "name": "Medical Record Department Specialist"
    }, {
        "img": "Professional-Central-Sterile-Supply-Department.jpg",
        "name": "Central Sterile Supply Department"
    }, {
        "img": "Professional-Purchasing-Department-Specialist.jpg",
        "name": "Purchasing Department Specialist"
    }, {
        "img": "Professional-Non-Professional-Services.jpg",
        "name": "Non Professional Services"
    }, {
        "img": "Professional-Dietary-Specialist.jpg",
        "name": "Dietary Specialist"
    }, {
        "img": "Professional-Radiology-Department.jpg",
        "name": "Radiology Department"
    }, {
        "img": "Professional-Operation-Theatre-Complex.jpg",
        "name": "Operation Theatre Complex"
    }, {
        "img": "Professional-Physical-Medicine-and-Rehabilitation-Specialist.jpg",
        "name": "Physical Medicine and Rehabilitation Specialist"
    }, {
        "img": "Professional-Paramedical-Specialist.jpg",
        "name": "Paramedical Specialist"
    }, {
        "img": "Professional-Technical-Specialist.jpg",
        "name": "Technical Specialist"
    }, {
        "img": "Professional-Support-Specialist.jpg",
        "name": "Support Specialist"
    }, {
        "img": "Professional-Nursing-Specialist.jpg",
        "name": "Nursing Specialist"
    }, {
        "img": "Professional-Clinical-Specialist.jpg",
        "name": "Clinical Specialist"
    }, {
        "img": "Professional-Non-Medical-Specialist.jpg",
        "name": "Non Medical Specialist"
    }, {
        "img": "Professional-Medical-Specialist.jpg",
        "name": "Medical Specialist"
    }
]

const Professional = ProfessionalData.map(item => (
    <div key={item.name} className={"item"}>
        <div className={"box"} data-aos="fade-up" data-aos-duration="1000" >
            <div className={"image-wrapper"} >
                <Image img={`/images/Professional/${item.name}.jpg`} alt={item.name} />
            </div >
            <div className={"box-content"} >
                <h5>{item.name}</h5>
                <span></span>
            </div >
        </div >
    </div>
))


const ProfessionalSliderSlides = () => {
    React.useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <FrequentSlider slides={4} responsive={true} >
            {Professional}
        </FrequentSlider>
    )
}

export default function ProfessionalSlider() {
    return (<section className={"category-section sec-padding"} >
        <div className={"container"} >
            <div className={"row"} >
                <div className={"col-12"} >
                    <div className={"sec-head text-center"} data-aos="fade-up" data-aos-duration="1000" >
                        <h3 className={"font-bold text-uppercase"}>Healthcare Professionals Categories</h3>
                        <p>Reach with our highly qualified healthcare professional profile.</p>
                    </div >
                </div >
            </div >
            <div className={"content-wrapper"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ProfessionalSliderSlides />
                    </div>
                </div>
            </div>
        </div>
    </section >)
}
