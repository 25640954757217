import React from 'react'
import DashHeader from '../../components/common/DashHeader'
import DashAside from '../../components/common/DashAside'
import Table from '../../components/common/Table';



const columns = [
  {
    name: "no",
    label: "Sl.No.",
    options: {
      filter: false,
      sort: true,
    }
  },
  {
    name: "title",
    label: "Name",
    options: {
      filter: true,
      sort: true,
    }
  },
  {
    name: "points",
    label: "E-Mail",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "points",
    label: "Status",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "paymentstatus",
    label: "Date Of Joining",
    options: {
      filter: true,
      sort: true,
    }
  },
];

const Referals = () => {
  return (
    <>
      <div id="dashboard">
        <div className={"wrapper"}>
          <DashHeader />
          <DashAside />
          <main>
            <div className="row">
              <div className="col-md-12">
                <div className="card referal-page">
                  <div className="card-header">
                    <div className="title">Refer a Friend and Earn Rewards!</div>
                  </div>
                  <div className="card-body">
                    <div className="referal-link">
                      <h5>Refer With Link:</h5>
                      <p>Sharing is Caring! Why not extend your benefits to your colleagues. Share the below link with your colleagues and let them enjoy the benefits of being a My Health Opinions - In Community member.</p>
                      <div className="link">
                        <p id="referralLink" user="64e4aa41b9ab97723223610c"></p>
                        <span onClick={() => { console.log("copyRefLink(this)") }}></span>
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <form action="/user/my/referals" method="put">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="referal-form-title">
                              <h5>Invite By Email:</h5>
                              <p>Add your colleagues email id below and start inviting them to earn referral bonus.</p>
                            </div>
                            <div className="referal-form" >
                              <ul>
                                <li>
                                  <a href={void (0)} className='btn btn-primary' onClick={() => { console.log("duplicate(this)") }}>
                                    <i className="fa-solid fa-plus"></i>
                                    Add More
                                  </a>
                                </li>
                                <li>
                                  <a href={void (0)} className='btn btn-primary' onClick={() => { console.log("remove(this)") }}>
                                    <i className="fa-solid fa-minus"></i>Remove
                                  </a>
                                </li>
                              </ul>
                              <frfs-email label='E-Mail' name="email" required="true" editable="true"></frfs-email>
                            </div>
                          </div>
                          <div className="col-sm-12 flex-right">
                            <button type="button" className="btn btn-primary" onClick={() => { console.log("validateAndSubmit(this)") }}>
                              Refer
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <Table table={"REFERAL HISTORY"} columns={columns} />
                </div>
              </div>
            </div>
          </main >
        </div >
      </div>
    </>
  )
}

export default Referals