import React from 'react'
import DashHeader from '../../components/common/DashHeader'
import DashAside from '../../components/common/DashAside'

const Support = () => {
  return (
    <>
      <DashHeader />
      <DashAside />
      Support</>
  )
}

export default Support