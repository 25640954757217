import React from 'react'
import AOS from 'aos';
import "aos/dist/aos.css";
import FrequentSlider from '../../slider/FrequentSlider';
import Image from "../../common/Image"

const SpecialistData = [
    {
        "img": "Specialist-anaesthesia.jpg",
        "name": "Anaesthesia"
    }, {
        "img": "Specialist-allergy.jpg",
        "name": "Allergy & Immunology"
    }, {
        "img": "Specialist-bariatric-surgery.jpg",
        "name": "Bariatric Surgery"
    }, {
        "img": "Specialist-aerospace-medicine.jpg",
        "name": "Aerospace Medicine"
    }, {
        "img": "Specialist-Musculoskeletal.jpg",
        "name": "Musculoskeletal"
    }, {
        "img": "Specialist-Allergy-Immunology.jpg",
        "name": "Allergy Immunology"
    }, {
        "img": "Specialist-Blood-Circulation.jpg",
        "name": "Blood Circulation"
    }, {
        "img": "Specialist-Brain-Nervous-System.jpg",
        "name": "Brain Nervous System"
    }, {
        "img": "Specialist-Cancer.jpg",
        "name": "Cancer"
    }, {
        "img": "Specialist-Digestive-System.jpg",
        "name": "Digestive System"
    }, {
        "img": "Specialist-Endocrine-System.jpg",
        "name": "Endocrine System"
    }, {
        "img": "Specialist-EyeEarNoseThroat.jpg",
        "name": "Eye Ear Nose Throat"
    }, {
        "img": "Specialist-Heart-Vascular-Disorders.jpg",
        "name": "Heart Vascular Disorders"
    }, {
        "img": "Specialist-Infectious-Diseases.jpg",
        "name": "Infectious Diseases"
    }, {
        "img": "Specialist-others.jpg",
        "name": "others"
    }, {
        "img": "Specialist-Psychology.jpg",
        "name": "Psychology"
    }, {
        "img": "Specialist-Reproductive-System.jpg",
        "name": "Reproductive System"
    }, {
        "img": "Specialist-Skin-Nails-Hair-Disorders.jpg",
        "name": "Skin Nails Hair Disorders"
    }
]

const specialist = SpecialistData.randomize(10).map(item => (
    <div key={item.name} className={"item"}>
        <div className={"box"} data-aos="fade-up" data-aos-duration="1000" >
            <div className={"image-wrapper"} >
                {/* <picture>
                    <source srcSet={`/images/Specialist/${item.img}`} type="image/webp" />
                    <source srcSet={`/images/Specialist/${item.img}`} type="image/jpg" />
                    <img src={`/images/Specialist/${item.img}`} alt="img" />
                </picture> */}
               <Image img={`/images/Specialist/${item.name}.jpg`} alt={item.name} />

            </div >
            <div className={"box-content"} >
                <h5>{item.name}</h5>
                <span></span>
            </div >
        </div >
    </div>
))


const SpecialistSliderSlides = () => {
    React.useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <FrequentSlider slides={4} responsive={true}>
            {specialist}
        </FrequentSlider>
    )
}

export default function SpecialistSlider(){
    return (<section className={"specialities-section  sec-padding"}>
    <div className={"container"}>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <div className={"sec-head text-center"} data-aos="fade-up" data-aos-duration="1000">
                    <h3 className={"font-bold text-uppercase"}>Healthcare Specialities</h3>
                    <p>Reach with our highly qualified healthcare professional profile.</p>
                </div>
            </div>
        </div>

        <div className={"content-wrapper"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <SpecialistSliderSlides />
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>)
}

