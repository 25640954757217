import React, { useState } from "react";
import Header from "../../components/visitor/Header";
import Footer from "../../components/visitor/Footer";
import Sign from "../../components/visitor/popup/Sign";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";


const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <img className={"chevron"} src={"/images/angle.svg"} alt="Chevron Down" />
      </>
    }
    className={"item"}
    buttonProps={{
      className: ({ isEnter }) =>
        `${"itemBtn"} ${isEnter && "itemBtnExpanded"}`,
    }}
    contentProps={{ className: "itemContent" }}
    panelProps={{ className: "itemPanel" }}
  />
);

const Cookies = () => {
  const [showSign, setShowSign] = useState(false);

  return (
    <>
      <Header />
      <Sign showSign={showSign} setShowSign={setShowSign} />
      <section class="inner-banner">
        <div class="container">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <div class="content text-center">
                <h3 className="text-capitalize font-bold">cookie policy</h3>
                <ul className="d-flex justify-content-center mt-4">
                  <li>
                    <a href="/">home</a>
                  </li>
                  <li>
                    <i class="fa-solid fa-angle-right"></i>
                  </li>
                  <li>
                    <p>cookie policy</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={"cookie-bg"}>
        <div className={"container"}>
          <div className="row">
            <div class="col-12 mb-4">
              <div class="content text-center">
                <div class="icon">
                  <img src="/images/icon-review.png" alt="icon-review" />
                </div>
                <p>
                  We use different types of cookies to optimize your experience
                  on our website. Click on the categories below to learn more
                  about their purposes. You may choose which types of cookies to
                  allow and can change your preferences at any time. Remember
                  that disabling cookies may affect your experience on the
                  website. You can learn more about how we use cookies by
                  visiting our Cookie Policy and Privacy Policy.
                </p>
              </div>
            </div>
          </div>
          <div className={"styles.accordion"}>
            <Accordion transition transitionTimeout={250}>
              <div className={"row"}>
                <div className={"col-12"}>
                  <AccordionItem header="Essentials" initialEntered>
                    <p>
                      These cookies are necessary to the core functionality of
                      our website and some of its features, such as access to
                      secure areas.
                    </p>
                    <p>Below full details for Essentials:</p>
                    <div class="custom-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Purpose</th>
                            <th>Type</th>
                            <th>Expires In</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>connection.sid</td>
                            <td>
                              Stores authentication and authorization of user
                              after login. This variable is used to provide
                              personalized user experience
                            </td>
                            <td>http_cookie</td>
                            <td>30 days / Logout</td>
                          </tr>
                          <tr>
                            <td>
                              twk_idm_key, TawkConnectionTime, twk_uuid_XXXX
                            </td>
                            <td>
                              Used to provide chat support to the respondent
                              live while working. These cookies are set by a
                              third party but chat feature is totally dependent
                              on this so it cannot be ignored
                            </td>
                            <td>http_cookie</td>
                            <td>Managed by third party(Tawk.to)</td>
                          </tr>
                          <tr>
                            <td>Google_RECAPTCHA</td>
                            <td>
                              Name of variable managed by google policy and is
                              managed by google and it’s subsidaries. This is
                              used for spam protection and robot detection
                            </td>
                            <td>http_cookie</td>
                            <td>Managed by third party (Google)</td>
                          </tr>
                          <tr>
                            <td>VisitorInfo*</td>
                            <td>
                              These cookie are used by Youtube. We have used
                              Youtube video to provide infographics, tutorials
                              and informative material in form of videos.
                            </td>
                            <td>http_cookie</td>
                            <td>Managed by Third party (Youtube)</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </AccordionItem>
                </div>

                <div className={"col-12 mt-4"}>
                  <AccordionItem header="Performance And Functionality">
                    <p>
                      These cookies are used to enhance the performance and
                      functionality of our websites but are nonessential to
                      their use. However, without these cookies, certain
                      functionality (like videos) may become unavailable.
                    </p>
                    <p>Below full details for Performance and Functionality:</p>
                    <div class="custom-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Purpose</th>
                            <th>Type</th>
                            <th>Expires In</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <p>_hjSessionUser_XXXXXXX</p>
                              <p>_hjFirstSeen</p>
                              <p>_hjIncludedInSessionSample</p>
                              <p>_hjSession_XXXXXXXX</p>
                              <p>_hjIncludedInPageviewSample</p>
                              <p>_hjAbsoluteSessionInProgress</p>
                            </td>
                            <td>
                              This cookie is used to proactive tracking of
                              issues in the website and application before a
                              visitor makes a remark.
                            </td>
                            <td>http_cookie</td>
                            <td>Managed by Third party (Hotjar)</td>
                          </tr>
                          <tr>
                            <td>
                              <p>_GA*</p>
                              <p>NID *</p>
                              <p>IDE*</p>
                              <p>ANID*</p>
                            </td>
                            <td>
                              These cookie are used by Google Analytics to
                              provide us information about user’s behaviour on
                              our website and application.
                            </td>
                            <td>http_cookie</td>
                            <td>Managed by Third party (Google)</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </AccordionItem>
                </div>

                <div className={"col-12 mt-4"}>
                  <AccordionItem header="Social Networking">
                    <p>
                      These cookies enable you to share our website's content
                      through third-party social networks and other websites.
                      These cookies may also be used for advertising purposes.
                    </p>
                  </AccordionItem>
                </div>

                <div className={"col-12 mt-4"}>
                  <AccordionItem header="Unclassified">
                    <p>
                      These are cookies that have not yet been categorized. We
                      are in the process of classifying these cookies with the
                      help of their providers.
                    </p>
                    <div class="custom-table">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Purpose</th>
                            <th>Type</th>
                            <th>Expires In</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Auth</td>
                            <td>
                              Auth token for autologin if user selects remember
                              me on this computer
                            </td>
                            <td>html_local_storage</td>
                            <td>persistent</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </div>
      </div>

      <section className={"panel-section sec-padding"}>
        <div className={"container"}>
          <div className={"row align-items-center"}>
            <div className={"col-md-8"}>
              <div className={"content-wrapper"}>
                <h3>Lets Get Started</h3>
                <p>
                  Be our community member and get rewarded with points and
                  credit paypal account after your loyal inputs
                </p>
              </div>
            </div>
            <div className={"col-md-4"}>
              <div className={"content-wrapper d-flex justify-content-center"}>
                <a
                  className={"bttn secondary-bttn"}
                  id="join-panel"
                  onClick={() => {
                    setShowSign(!showSign);
                  }}
                >
                  {" "}
                  join now
                  <span className="angle-icon">
                    <i className="fa-solid fa-angle-right"></i>
                    <i className="fa-solid fa-angle-right"></i>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Cookies;
