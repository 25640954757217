import React from 'react'
import Image from '../../common/Image'


const Surveyopiniondata = [
    {
        "img": "acc-dashboard.jpg",
        "name": "Acc Dashboard"
    }, {
        "img": "Survey-sms.jpg",
        "name": "SMS"
    }, {
        "img": "Survey-WhatsApp.jpg",
        "name": "Whatsapp"
    }, {
        "img": "Survey-email-logo.jpg",
        "name": "Email"
    },
]

const SurveyOpinion = () => {
    return (
        <>
            {
                Surveyopiniondata.map(item => (
                    <div key={item.name} className={"col-sm-6 col-md-3"}>
                        <div className={"item"} data-aos="fade-up" data-aos-duration="1000">
                            <div className={"box"} >
                                <div className={"image-wrapper"} >
                                    <Image img={`/images/notify/${item.name}.jpg`} alt={item.name} />
                                </div>
                                <div className={"box-content box-content-alignment"} >
                                    <h5>{item.name}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    )
}

export default function Features(){
    return (<section className={"survey-opinion-flexibility sec-padding"} >
    <div className={"container"} >
        <div className={"row"} >
            <div className={"col-12"} >
                <div className={"sec-head} text-center"} data-aos="fade-up" data-aos-duration="1000" >
                    <h3 className={"font-bold text-uppercase"}>Survey Opinion Flexibility</h3>
                </div >
            </div >
        </div >
        <div className={"content-wrapper"}>
            <div className={"row"}>
                <SurveyOpinion />
            </div>
        </div>
    </div>
</section>)
}
