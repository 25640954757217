import React, { useContext, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/visitor/Header";
import Footer from "../../components/visitor/Footer";
import Sign from "../../components/visitor/popup/Sign";

import HomeBanner from "../../components/visitor/home/Banner";
import HomeAbout from "../../components/visitor/home/About";
import Rewards from "../../components/visitor/home/Rewards";
import Counter from "../../components/visitor/home/Counter";
import Features from "../../components/visitor/home/Features";
import ProfessionalSlider from "../../components/visitor/home/ProfessionalSlider";
import SpecialistSlider from "../../components/visitor/home/SpecialistSlider";
import Solutions from "../../components/visitor/home/Solutions";
import TestimonialSlider from "../../components/visitor/home/TestimonialSlider";
import Image from "../../components/common/Image";
import api from "../../modules/api";
import { callAllEndpoints } from "../../requents";
import { PopupContext } from "../../PopupContext";
import ForgotPassword from "../../components/visitor/popup/ForgotPassword";

// Importing Components Ending


const Home = () => {
  const navigate = useNavigate();
  const popup = useContext(PopupContext)

  const endpoints = {
    lang: "/api/v1/options/lang",
    country: "/api/v1/options/country/my",
    usercn: "https://admin.sampledge.com/api/v1/countries/lockbyip",
    department: "/api/v1/options/healthcare",
    hospitalType: "/api/v1/options/hospital-type",
    education: "/api/v1/options/hc-education",
    bedSize: "/api/v1/options/bed-size",
    ethnicity: "/api/v1/options/ethnicity",
    unfit: "/api/v1/options/unfit",
  }

  useEffect(() => {
    callAllEndpoints({ endpoints })
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <>

      <Header />
      <Sign {...popup} />
      <ForgotPassword {...popup} />
      <section className={"slider-banner"}>
        <div className={"banner-form"}>
          <div className={"content-wrapper"}>
            <div className={"form-head"}>
              <h3>Ready to Join Our Panel</h3>
              <p>Please select your identity</p>
            </div>
            <form>
              <div className={"login-type"}>
                <div className={"doctor-type"} onClick={() => {
                  navigate("/register/doctor");
                }}>
                  <label htmlFor="sign-doctor-type">
                    <Image
                      alt="Sign up As Doctor"
                      img="/images/home/forms/doctor-type.png"
                    />
                  </label>
                  <input

                    type="radio"
                    name="login-type-home"
                    id="sign-doctor-type-home"
                  />
                  <label className={"cbx"} htmlFor="sign-doctor-type"></label>
                  <h4>Doctor</h4>
                </div>
                <div className={"patient-type"} onClick={() => {
                  navigate("/register/patient");
                }}>
                  <label htmlFor="sign-patient-type">
                    <Image
                      alt="Sign up As Patient"
                      img="/images/home/forms/patient-type.png"
                    />
                  </label>
                  <input

                    type="radio"
                    name="login-type-home"
                    id="sign-patient-type-home"
                  />
                  <label className={"cbx"} htmlFor="sign-patient-type"></label>
                  <h4>Patient / Care taker</h4>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={"banner-dollar"}>
          <img src={`/images/home/forms/money-bag.png`} alt="" />
        </div>
        <HomeBanner {...popup} />
      </section>

      <div className={"hidden"} id="about"></div>
      <HomeAbout />

      <div className={"hidden"} id="professionals"></div>
      <Rewards />
      <Counter />
      <Features />
      <ProfessionalSlider />
      <div className={"hidden"} id="specialties"></div>
      <SpecialistSlider />
      <div className={"hidden"} id="solutions"></div>
      <Solutions />

      <section className={"panel-section sec-padding"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-md-8"}>
              <div className={"content-wrapper"}>
                <h3>Get Paid to take Survey Online.</h3>
                <p>
                  Join Today and you will get Free Reward for basic Profile
                  questions.
                </p>
              </div>
            </div>
            <div className={"col-md-4"}>
              <div className={"content-wrapper d-flex justify-content-center"}>
                <a
                  className={"bttn secondary-bttn"}
                  id="join-panel"
                  onClick={() => {
                    popup.handleSignupToggle();
                  }}
                >
                  {" "}
                  join our pannel
                  <span className="angle-icon">
                    <i className="fa-solid fa-angle-right"></i>
                    <i className="fa-solid fa-angle-right"></i>
                    <i className="fa-solid fa-angle-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className={"hidden"} id="testimonial"></div>
      <TestimonialSlider />
      <Footer />
    </>
  );
};

export default Home;
