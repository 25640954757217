import React, { useState } from "react";
import FrequentSlider from "../../slider/FrequentSlider";
import Image from "../../common/Image";
const BannerData = [
  {
    name: "bg1.jpg",
    h4: "Join our global Healthcare Professional Community and get rewarded for participating in Our Online Medical Market Research",
    p: "More than 2 lakh Medical professionals registered with us.",
  },
  {
    name: "bg2.jpg",
    h4: "We are the one who offer the High Paid research opportunity to your inbox. Your Opinion Matters! Share your Expert opinion with us.",
    p: "Your Opinion Matters! Share your Expert opinion with us.",
  },
  {
    name: "bg3.jpg",
    h4: "Expert Insights Matter: Share Your Professional Opinion",
    p: "Your Expertise Counts,Participate in Our Medical Professional Survey",
  },
  {
    name: "bg4.jpg",
    h4: "Transforming Healthcare, One Opinion at a Time",
    p: "Building a Patient-Centric Future: Participate in Our Survey",
  },
];

const HomeBanner = (props) => {
  const {handleSignupToggle} = props
  return (
    <>
      {
        <FrequentSlider speed={2000} responsive={false}>
          {BannerData.map((item) => (
            <div key={item.h4} className={"banner-item"}>
              <div className={"banner-image"}>
                {/* <picture>
                <img src={`/images/Home-Banner/${item.img}`} alt="Banner" />
                </picture> */}
                <Image
                  img={`/images/home/banner/${item.name}`}
                  alt={item.name}
                />
              </div>
              <div className={"banner-caption"}>
                <h4>{item.h4}</h4>
                <p>{item.p}</p>
                <a
                  href={void 0}
                  onClick={() => {handleSignupToggle()}}
                  className={"bttn secondary-bttn"}
                >
                  Join Now
                </a>
              </div>
            </div>
          ))}
        </FrequentSlider>
      }
    </>
  );
};

export default HomeBanner;