import React, { useState, useEffect } from 'react'

const Quote = (props) => {
    const {showQuote, handleQuoteToggle } = props
    return (
        <>
            <div className={showQuote ? "popup-request-form m-open" : "popup-request-form"} >
                <div className={"request-form"} >
                    <div className={"cross-bttn"} onClick={()=>{handleQuoteToggle()}} ></div >
                    <div className={"inner"} >
                        <div className={"image-wrapper"} >
                            <h3>Request a Quote</h3>
                        </div >
                        <div className={"content-wrapper"} >
                            <form method="post" action="/api/v1/quotes">
                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <div className={"form-field"}>
                                            <label htmlFor="name">Name</label>
                                            <input required type="text" data-error="Please type your name correctly" name="name" id="r-name" placeholder="Enter your name" pattern="(([a-zA-Z]{1,50}) ?){1,3}" />
                                            <span className={"error"}></span>
                                        </div >
                                        <div className={"form-field"} >
                                            <label htmlFor="email">Email</label>
                                            <input required type="email" data-error="Please type your email correctly" name="email" id="r-email" placeholder="Enter your email" />
                                            <span className={"error"} ></span >
                                        </div >
                                        <div className={"form-field"} >
                                            <label htmlFor="company">Company</label>
                                            <input required type="text" data-error="Your company name is required" name="company" id="r-company" placeholder="Enter company name" />
                                            <span className={"error"} ></span >
                                        </div >
                                    </div >
                                    <div className={"col-md-6"} >
                                        <div className={"form-field"} >
                                            <label htmlFor="message">message</label>
                                            <textarea required name="message" data-error="Please write your query, this will help us to serve you better" id="r-message" cols="30" rows="10" placeholder="enter your message"></textarea>
                                            <span className={"error"} ></span >
                                        </div >
                                        <div className={"form-field"} >
                                            <input type="submit" value="request quote" />
                                        </div >
                                    </div >
                                </div >
                            </form >
                        </div >
                    </div >
                </div >
            </div >
        </>
    )
}

export default Quote