import React from 'react'
import { useNavigate } from "react-router-dom"
const WorkProgressPage = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="work-progress-body">
                <div className="container">
                    <div className="setting-icon">
                        <i class="fa-solid fa-gear"></i>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 g-5">
                            <div className="content">
                                <h1>Sorry, we're doing some work on this page</h1>
                                <p>Thank you for being patient. The work is in progress and we will be back shortly</p>
                                <button className='bttn secondary-bttn' onClick={() => {
                                    navigate("/");
                                }}>Back To Home</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkProgressPage