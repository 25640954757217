import React, { createContext } from 'react'

export const PopupContext = createContext()
const Popup = (props) => {
    const [showLogin, setShowLogin] = React.useState(false)
    const [showSignup, setShowSignup] = React.useState(false)
    const [showQuote, setShowQuote] = React.useState(false)
    const [showForgot, setShowForgot] = React.useState(false)

    const handleLoginToggle = () => {
        setShowLogin(!showLogin)
        setShowSignup(false)
        setShowQuote(false)
        setShowForgot(false)
    }

    const handleSignupToggle = () => {
        setShowSignup(!showSignup)
        setShowLogin(false)
        setShowQuote(false)
        setShowForgot(false)
    }

    const handleQuoteToggle = () => {
        setShowQuote(!showQuote)
        setShowLogin(false)
        setShowSignup(false)
        setShowForgot(false)
    }

    const handleForgotToggle = () => {
        setShowQuote(false)
        setShowLogin(false)
        setShowSignup(false)
        setShowForgot(!showForgot)
    }


    return (
        <PopupContext.Provider value={{ showLogin, showSignup, showQuote, handleLoginToggle, handleSignupToggle, handleQuoteToggle, showForgot, handleForgotToggle }}>
            {props.children}
        </PopupContext.Provider>

    )
}

export default Popup